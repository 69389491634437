<template>   
  <v-dialog
      v-model="dialog"
      :width="(isMobile)?'80%':'60%'"
    >
      <v-card>
        <v-toolbar      
            class="border border-b-sm"
            color="white"
          >
            <v-icon
                :icon="dialogTitle.icon"
                size="large"
                :color="dialogTitle.color"
                class="my-2 ml-5 mr-3"
            ></v-icon>
            <v-divider :thickness="3" color="error" vertical></v-divider>
            <v-toolbar-title class="text-h6">             
              <span :color="(dialogTitle.color != '')?dialogTitle.color:'grey-darken-3'">{{ dialogTitle.title }}</span>         
            </v-toolbar-title>
            <template v-slot:append>      
              <v-btn
                  icon="mdi-close"
                  density="comfortable"
                  variant="plain"
                  @click="dialog = false"
              ></v-btn>
            </template>
          </v-toolbar>
        <v-card-text>
          <v-list-item :key="`dialog-${index}`" v-for="(text,index) in dialogContent">
              <v-list-item-title class="my-label">{{text}}
              </v-list-item-title>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  <v-container fluid class="page-content">
    <v-row>
      <v-col xxl="6" xl="6" lg="6" md="12" cols="12">
        <v-card elevation="5" class="py-5 h-100">
          <highcharts :options="chart"></highcharts>
        </v-card>
      </v-col>
      <v-col xxl="6" xl="6" lg="6" md="12" cols="12">
        <v-card elevation="5" class="py-5 h-100">
        <v-data-table-virtual
          :headers="headersHisto"
          :items="historique"
          height="400"
          item-value="name"
        ></v-data-table-virtual>
        </v-card>
      </v-col>
      <v-col xxl="6" xl="6" lg="6" md="12" cols="12">
        <v-card elevation="5" class="py-5 h-100">
        <v-data-table-virtual
          :headers="headersJournal"
          :items="journal"
          height="400"
          item-value="name"
        ></v-data-table-virtual>
        </v-card>
      </v-col>
      <v-col xxl="6" xl="6" lg="6" md="12" cols="12">
        <v-card elevation="5" class="py-5 h-100"><highcharts :options="bar"></highcharts>
        </v-card></v-col>
      <v-col xxl="12" xl="12" lg="12" md="12" cols="12">
        <v-card elevation="5" class="py-5 h-100 ">
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn prepend-icon="mdi-alert" class="ma-5" elevation="5">Acquittement défaut</v-btn>
            <v-btn prepend-icon="mdi-water" class="ma-5" elevation="5">Actionner électrovanne</v-btn>
            <v-btn prepend-icon="mdi-account-circle" class="ma-5" elevation="5">Action 3</v-btn>
            <v-btn prepend-icon="mdi-account-circle" class="ma-5" elevation="5">Action 4</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    
    
  </v-container>
</template>
<script>
var apiFileUrl = "api_domms.php";
//import { createApp } from "vue";
import axios from "axios";
import Highcharts from "highcharts";
//import { Chart } from "highcharts-vue";
//import Highcharts from "highcharts";
import hcMore from "highcharts/highcharts-more";
//import Vidle from 'v-idle'
import { VMap, VMapGoogleTileLayer, VMapOsmTileLayer  , VMapZoomControl, VMapAttributionControl, VMapPinMarker } from 'vue-map-ui';
hcMore(Highcharts);
//import moment from "moment";
/*import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import highchartsMore from "highcharts/highcharts-more";*/
//const app = createApp();
/*highchartsMore(Highcharts);
app.use(HighchartsVue, {
highcharts: Highcharts,
});*/
import { useAuthStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useNavbarStore } from '@/stores';
export default {
  components: {
    VMap,
    VMapGoogleTileLayer,
    VMapOsmTileLayer ,
    VMapZoomControl,
    VMapAttributionControl,
    VMapPinMarker,
  },
  props : {
  },
  data () {
    return {
    title : 'Hyperviseur',
    tabs: [{id : 0, type: 'Alarme', total : 0, color : 'orange', icon : 'mdi-alarm-light'},
          {id : 1, type: 'Défaut', total : 0, color : 'red', icon : 'mdi-alert'}],
    tab : 0,
    right : 'alarm',
    value: "",
    loading: false,
    mapmode:false,
    dialog:false,
    dialogContent:[],
    hydrowebs : [],
    test : 0,
    intervalId: null,
    dataInit : false,
    timeRefresh : 15000,
    mapCenter : [43.557259, 4.081624],
    selectedIndex : 1,
    legendMapItems : [{icon: 'mdi-map-marker', color:'#66BB6A', text: 'Ok'},
                      {icon: 'mdi-map-marker', color:'#E57373', text: 'Défaut'},
                      {icon: 'mdi-circle', color:'#FFCC80', text: 'Alarme'},
                      {icon: 'mdi-map-marker', color:'#616161', text: 'COM perdue'}],
    dialogTitle : {title : 'Informations', color : 'grey-darken-3', icon : 'mdi-information-box'},
    factory : {},
    chart : {},
    bar : {},
    id : 0,
    headersJournal: [
          { title: 'Date', align: 'center', width : '25%', key: 'date' },
          { title: 'Titre', align: 'start', width : '65%', key: 'title' },
          { title: 'Valeur', align: 'end', width : '10%', key: 'value' },
    ],
    headersHisto: [
          { title: 'Date', align: 'center', width : '25%', key: 'date' },
          { title: 'Titre', align: 'start', width : '35%', key: 'title' },
          { title: 'Utilisateur', align: 'center', width : '20%', key: 'user' },
          { title: 'Ancienne Valeur', align: 'end', width : '10%', key: 'old' },
          { title: 'Nouvelle Valeur', align: 'end', width : '10%', key: 'new' },
    ],
    journal : [
      {
        date: '2024-07-01 06:01:00',
        title: 'Alarme 1',
        value: 'Non',
      },
      {
        date: '2024-07-01 06:00:00',
        title: 'Acquittement Alarme 1',
        value: 'Oui',
      },
      {
        date: '2024-07-01 05:55:10',
        title: 'Alarme 1',
        value: 'Oui',
      },{
        date: '2024-07-01 05:55:10',
        title: 'Alarme 2',
        value: 'Oui',
      },{
        date: '2024-07-01 05:55:10',
        title: 'Défaut 1',
        value: 'Oui',
      },{
        date: '2024-07-01 05:55:10',
        title: 'Défaut 3',
        value: 'Oui',
      },
    ],
    historique : [
      {
        date: '2024-07-01 06:01:00',
        title: 'Alarme 1',
        user : 'Utilisateur 1',
        old: 'Oui',
        new: 'Non',
      },
      {
        date: '2024-07-01 06:00:00',
        title: 'Acquittement Alarme 1',
        value: 'Oui',
        user : 'Utilisateur 1',
        old: 'Oui',
        new: 'Non',
      },
      {
        date: '2024-07-01 05:55:10',
        title: 'Alarme 1',
        value: 'Oui',
        user : 'Utilisateur 1',
        old: 'Oui',
        new: 'Non',
      },{
        date: '2024-07-01 05:55:10',
        title: 'Alarme 2',
        value: 'Oui',
        user : 'Utilisateur 1',
        old: 'Oui',
        new: 'Non',
      },{
        date: '2024-07-01 05:55:10',
        title: 'Défaut 1',
        value: 'Oui',
        user : 'Utilisateur 1',
        old: 'Oui',
        new: 'Non',
      },{
        date: '2024-07-01 05:55:10',
        title: 'Défaut 3',
        value: 'Oui',
        user : 'Utilisateur 1',
        old: 'Oui',
        new: 'Non',
      },
    ],
  }
  },  
computed: {
  windowsTab:  () => {
    const navbarStore = useNavbarStore();
    console.log(navbarStore.getTab());
    return navbarStore.getTab();
  },
  isMobile : () => {
    const navbarStore = useNavbarStore();
    return navbarStore.getMobile();
  }
},
mounted() {
    this.init();
    this.id = this.$route.params.id;
    //this.startInterval();
  },
methods: { 
  /*startInterval: function() {
    this.intervalId = setInterval(() => {
      this.getData();
    }, this.timeRefresh);
  },*/
  getData(){
    if(this.dataInit){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      console.log('REFRESH'+Date());
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydrowebByUser');
        formData.append('id', authStore.user.id_user);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {    
            if(response.data){         
              var idListHydroweb = vm.hydrowebs.map(el => el.id);
              for (var id in response.data) {                          
                    var indexHydroweb = idListHydroweb.indexOf(response.data[id].id); 
                    if(response.data[id].hyperviseur[2]){                                                 
                      var idListNiveau = vm.hydrowebs[indexHydroweb].niveauSeries[0].data.map(el => el.x);             
                      for (let index = 0; index < response.data[id].hyperviseur[2].length; index++) {
                        var indexNiveau = idListNiveau.indexOf(response.data[id].hyperviseur[2][index].name); 
                        vm.hydrowebs[indexHydroweb].niveauSeries[0].data[indexNiveau].y = response.data[id].hyperviseur[2][index].value;
                      }
                    }
                    if(response.data[id].hyperviseur[1]){
                      vm.hydrowebs[indexHydroweb].puissanceValue = response.data[id].hyperviseur[1][0].value;
                      vm.hydrowebs[indexHydroweb].puissance.series[0].data[0] = response.data[id].hyperviseur[1][0].value;
                    }
                    
                    var idListProduction = vm.hydrowebs[indexHydroweb].production.map(el => el.label);  
                    if(response.data[id].hyperviseur[3]){                        
                      var indexProduction = idListProduction.indexOf(response.data[id].hyperviseur[3][0].nameType);
                      vm.hydrowebs[indexHydroweb].production[indexProduction].value = response.data[id].hyperviseur[3][0].value
                    }                     
                    vm.hydrowebs[indexHydroweb].com = response.data[id].com
                    vm.hydrowebs[indexHydroweb].alarme = response.data[id].alarme
                    vm.hydrowebs[indexHydroweb].default = response.data[id].default                                     
                }                         
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    }
  },
  init(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      navbarStore.setTab(this.tab);
      navbarStore.setRight(this.right);   
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
      //const api = this.apiUrl;
      try{
        this.hydrowebs = [{id : 0, name : 'Ponton A', shortName : 'A', latitude : '43.559793', longitude : '4.082189', com : 1, alarme : 0, default : 0, conso_elec : 150, conso_eau : 10, chart : {}, bar : {}}, 
        {id : 0, name : 'Ponton B', shortName : 'B', latitude : '43.559453', longitude : '4.082486', com : 1, alarme : 0, default : 0, conso_elec : 100, conso_eau : 20, chart : {}, bar : {}},
        {id : 0, name : 'Ponton C', shortName : 'C', latitude : '43.559087', longitude : '4.082761', com : 1, alarme : 0, default : 0, conso_elec : 20, conso_eau : 15, chart : {}, bar : {}},
        {id : 0, name : 'Ponton D', shortName : 'D', latitude : '43.558777', longitude : '4.083005', com : 1, alarme : 1, default : 3, conso_elec : 0, conso_eau : 0, chart : {}, bar : {}},
        {id : 0, name : 'Ponton E', shortName : 'E', latitude : '43.558394', longitude : '4.083314', com : 1, alarme : 0, default : 0, conso_elec : 10, conso_eau : 2, chart : {}, bar : {}},
        {id : 0, name : 'Ponton F', shortName : 'F', latitude : '43.557999', longitude : '4.083634', com : 1, alarme : 1, default : 0, conso_elec : 60, conso_eau : 5, chart : {}, bar : {}},
        {id : 0, name : 'Ponton G', shortName : 'G', latitude : '43.557668', longitude : '4.085245', com : 0, alarme : 0, default : 0, conso_elec : 0, conso_eau : 0, chart : {}, bar : {}}];          
        this.factory = this.hydrowebs[this.$route.params.id];
        this.chart = {
                                chart: {
                                    type: 'line',
                                    height : '300px'
                                },

                                title: {
                                    text: ''
                                },

                                credits : {
                                    enabled : false
                                },
                                xAxis: {
                                    gridLineWidth: 1,
                                    labels: {
                                        align: 'left',
                                        x: -7,
                                        y: 15
                                    },
                                    categories: ['00', '02', '04', '06', '08', '10', '12','14', '16', '18', '20', '22']
                                },
                                // the value axis
                                yAxis: [{ // left y axis
                                          title: {
                                              text: 'Kwh'
                                          },
                                          labels: {
                                              align: 'left',
                                              x: -20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                        },
                                        { // right y axis
                                          gridLineWidth: 0,
                                          opposite: true,
                                          title: {
                                              text: 'l/h'
                                          },
                                          labels: {
                                              align: 'right',
                                              x: 20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                      }
                                ],
                                legend: {
                                    align: 'left',
                                    verticalAlign: 'top',
                                    borderWidth: 0
                                },

                                tooltip: {
                                    shared: true,
                                    crosshairs: true
                                },
                                plotOptions: {
                                  line: {
                                      dataLabels: {
                                          enabled: false
                                      },
                                      enableMouseTracking: true
                                  }
                                },

                                series: [ {
                                      name: 'Electricité',
                                      type: 'line',
                                      color : '#F57F17',
                                      yAxis: 0,
                                      data: [100.0, 180.2, 230.1, 270.9, 320.2, 306.4, 309.8, 308.4, 305.5, 290.2,
                                          220.0, 207.8, ]
                                  }, {
                                      name: 'Eau',
                                      type: 'line',
                                      color : '#00838F',
                                      yAxis: 1,
                                      data: [16.0, 18.2, 23.1, 27.9, 32.2, 36.4, 39.8, 38.4, 35.5, 29.2,
                                          22.0, 17.8,]
                                  }]
                              };
          this.bar = {
            chart: {
                type: 'column'
            },
            title: {
                text: '',
                align: 'left'
            },
                                credits : {
                                    enabled : false
                                },
            xAxis: {
                categories: ['01/07', '02/07', '03/07', '04/07', '05/07', '06/07'],
                crosshair: true,
                accessibility: {
                    description: 'Jours'
                }
            },
            yAxis: [{
                min: 0,
                title: {
                    text: 'kw'
                }
            },{
                min: 0,
                opposite: true,
                title: {
                    text: 'm3'
                }
            }],
            tooltip: {
                valueSuffix: ' m3'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'Electricité',
                    color : '#F57F17',
                    data: [100, 254, 50, 544, 255, 50]
                },
                {
                    name: 'Eau',
                    color : '#00838F',
                    yAxis: 1,
                    data: [80, 254, 0, 54, 253, 150]
                }
            ]
          };   
        this.tabs[0].total = this.hydrowebs[this.$route.params.id].alarme;
        this.tabs[1].total = this.hydrowebs[this.$route.params.id].default;
        navbarStore.setTabs(this.tabs);
        navbarStore.setTitle(this.hydrowebs[this.$route.params.id].name);
      } catch (error) {   
        alertStore.error(error);
      } 
  },
  checkReadyState(checker) {
    if (checker.readyState === 4) {
        if ((checker.status == 200) || (checker.status == 0)) {     
          console.log('OK');
        }
        else {          
          console.log('NOK');
          return;
        }
    }
  },
  testApi:  () => {
    var formData = new FormData();
    //formData.append('action', 'getOldPalette');
    //var vm = this;
    axios
      .post($this.apiUrl+'/'+apiFileUrl, formData)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  displayDialog(id,title){
    const alertStore = useAlertStore();
    var action = '';
    var icon = 'mdi-information-box';
    var color = 'grey-darken-3';
    if(title == 'Alarme'){
      action = 'getAlarmeByHydroweb';
      icon = 'mdi-alarm-light';
      color = 'orange-darken-1';
    }else if(title == 'Défaut'){
      action = 'getDefautByHydroweb';
      icon = 'mdi-alert';
      color = 'red-darken-1';
    }
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', action);
        formData.append('id', id);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {         
          if(response.data){
            vm.dialog = true;
            vm.dialogTitle.title = title;
            vm.dialogTitle.color = color;
            vm.dialogTitle.icon = icon;
            vm.dialogContent = [];  
            for (let i = 0; i < response.data.length; i++) {
              vm.dialogContent.push(response.data[i].text);            
            }
          }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 

  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
},
};
</script>
<style scoped>
.highcharts-figure,
.highcharts-data-table table {
min-width: 310px;
max-width: 500px;
margin: 1em auto;
}

.highcharts-data-table table {
font-family: Verdana, sans-serif;
border-collapse: collapse;
border: 1px solid #ebebeb;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}

.highcharts-data-table caption {
padding: 1em 0;
font-size: 1.2em;
color: #555;
}

.highcharts-data-table th {
font-weight: 600;
padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
background: #f8f8f8;
}

.highcharts-data-table tr:hover {
background: #f1f7ff;
}
.v-item-group.v-bottom-navigation .v-btn {
min-width: 0px !important;
}
.v-item-group.v-bottom-navigation--horizontal
.v-btn
> .v-btn__content
> .v-icon {
margin-right: 0px !important;
}

.centrale-box{
  padding-bottom: 55px;
}

.puissance-box {
padding: 0px;
margin-top: -35px;
}
.puissance-box .puissance-chart {
margin-bottom: 25px !important;
}
.puissance-box .v-row .v-col {
padding: 2px !important;
}

.niveaux-box {
margin-top: -35px;
}
.alarme-box {
text-align: center;
padding-top: 0px !important;
}
.comTrue {
background-color: #00b62d;
}

.comFalse {
background-color: #c90300;
animation-name: COM0;
animation-duration: 2s;
animation-iteration-count: infinite;
}

.v-btn--stacked.v-btn--size-default {
    min-width: 30px;
    padding: 0 0px;
}
@keyframes COM0 {
0% {
  background-color: #c90300;
}
25% {
  background-color: #c90300d3;
}
50% {
  background-color: #c90300b7;
}
75% {
  background-color: #c90300b9;
}
100% {
  background-color: #c90300e0;
}
}
</style>
<style>
/*import styles VUE MAP*/
@import 'leaflet/dist/leaflet.css';
@import 'vue-map-ui/dist/normalize.css';
@import 'vue-map-ui/dist/style.css';
@import 'vue-map-ui/dist/theme-all.css';
.productionInput input{
  padding: 0.5em !important;
  text-align: center !important;
  font-size : 0.9em;
}

.marker-round-icon {
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 23px;
  text-align: center;
}
</style>
