<template> 
  <v-container fluid class="page-content">
    <div class="about">
      <h1>This is an about page</h1>
    </div>
  </v-container>
</template>
<script>
//import axios from "axios";
import { useNavbarStore } from '@/stores';
//import { useAlertStore } from '@/stores';
import { useAuthStore } from '@/stores';
export default {
  props : {
  },
  data () {
    return {
      title : 'Administration',
      tabs: [],
      tab : null,
    }
  },  
computed: {
  /*ready:  () => {
    this.init();
  },*/
},
mounted() {
  this.init();
},
methods: {
  init(){
      //const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      const authStore = useAuthStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(null);
      navbarStore.setRight(null);  
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);           
  }
},
};
</script>