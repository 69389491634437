<template>
  <v-container fluid class="page-content">    
      <v-row density="compact">
        <v-col lg="12" md="12" sm="12" xs="12">
          <highcharts style="height:100%" :options="chartLine"></highcharts>
        </v-col>
        <v-col lg="12" md="12" sm="12" xs="12">
          <highcharts style="height:100%" :options="chartBar"></highcharts>
        </v-col>
      </v-row>        
  </v-container>
</template>
<script>
const resizeObserver = new ResizeObserver(entries => {
   // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
   window.requestAnimationFrame(() => {
     if (!Array.isArray(entries) || !entries.length) {
       return;
     }
     // your code
   });
});
//var apiFileUrl = "api_domms.php";
//import axios from "axios";
import { useNavbarStore } from '@/stores';
import { useAuthStore } from '@/stores';
export default {
  data () {
    const srcs = {
        1: require('@/assets/icons/barrage.svg'),
        2: require('@/assets/icons/turbine.svg'),
        3: require('@/assets/icons/maisonVerte.svg'),
      }
    return {
      title : 'Statistiques',
      tabs: [],
      tab : 0,
      right : false,
      centralesSearch: [
          { id: 1, name: 'Ouverture Vanne RD/RG', type : 'table', icon: 'mdi-table-large' },
          { id: 2, name: 'Stats 2', icon: 'mdi-chart-bar' },
        ],
      headers : [ {
            title: 'Jours',
            key: 'day',
            align : 'center'
          },
          { title: 'Ouverture', key: 'open', align : 'center' },
          { title: 'Fermeture', key: 'close', align : 'center' }],
      stats: [],
      chartLine : {
                                chart: {
                                    type: 'line',
                                    height : '300px'
                                },

                                title: {
                                    text: ''
                                },

                                credits : {
                                    enabled : false
                                },
                                xAxis: {
                                    gridLineWidth: 1,
                                    labels: {
                                        align: 'left',
                                        x: -7,
                                        y: 15
                                    },
                                    categories: ['00', '02', '04', '06', '08', '10', '12','14', '16', '18', '20', '22']
                                },
                                // the value axis
                                yAxis: [{ // left y axis
                                          title: {
                                              text: 'Kwh'
                                          },
                                          labels: {
                                              align: 'left',
                                              x: -20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                        },
                                        { // right y axis
                                          gridLineWidth: 0,
                                          opposite: true,
                                          title: {
                                              text: 'l/h'
                                          },
                                          labels: {
                                              align: 'right',
                                              x: 20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                      }
                                ],
                                legend: {
                                    align: 'left',
                                    verticalAlign: 'top',
                                    borderWidth: 0
                                },

                                tooltip: {
                                    shared: true,
                                    crosshairs: true
                                },
                                plotOptions: {
                                  line: {
                                      dataLabels: {
                                          enabled: false
                                      },
                                      enableMouseTracking: true
                                  }
                                },

                                series: [ {
                                      name: 'Electricité',
                                      type: 'line',
                                      color : '#F57F17',
                                      yAxis: 0,
                                      data: [100.0, 180.2, 230.1, 270.9, 320.2, 306.4, 309.8, 308.4, 305.5, 290.2,
                                          220.0, 207.8, ]
                                  }, {
                                      name: 'Eau',
                                      type: 'line',
                                      color : '#00838F',
                                      yAxis: 1,
                                      data: [16.0, 18.2, 23.1, 27.9, 32.2, 36.4, 39.8, 38.4, 35.5, 29.2,
                                          22.0, 17.8,]
                                  }]
                              },
    chartBar : {
    chart: {
        type: 'column'
    },
    title: {
        text: '',
        align: 'left'
    },
    xAxis: {
        categories: ['2024-06-01', '2024-06-02', '2024-06-03', '2024-06-04', '2024-06-05', '2024-06-06', '2024-06-07','2024-06-08', '2024-06-09', '2024-06-10', '2024-06-11', '2024-06-12'],
        crosshair: true,
        accessibility: {
            description: 'Date'
        }
    },
    yAxis: [{ // left y axis
                                          title: {
                                              text: 'Kwh'
                                          },
                                          labels: {
                                              align: 'left',
                                              x: -20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                        },
                                        { // right y axis
                                          gridLineWidth: 0,
                                          opposite: true,
                                          title: {
                                              text: 'l/h'
                                          },
                                          labels: {
                                              align: 'right',
                                              x: 20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                      }
                                ],
    tooltip: {
        valueSuffix: ' (1000 MT)'
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [ {
                                      name: 'Electricité',
                                      color : '#F57F17',
                                      yAxis: 0,
                                      data: [1000.0, 1800.2, 2300.1, 2700.9, 3200.2, 3060.4, 3090.8, 3080.4, 3050.5, 2900.2,
                                          2200.0, 2070.8, ]
                                  }, {
                                      name: 'Eau',
                                      color : '#00838F',
                                      yAxis: 1,
                                      data: [160.0, 180.2, 230.1, 270.9, 320.2, 360.4, 390.8, 380.4, 350.5, 290.2,
                                          220.0, 178.0,220.0, 170.8,]
                                  }]
}
    }
  },
  computed: {
    statsValue:  () => {
      const navbarStore = useNavbarStore();
      console.log(navbarStore.getStatsSelected());
      return navbarStore.getStatsSelected();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      const authStore = useAuthStore();
      const navbarStore = useNavbarStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(this.tab);
      navbarStore.setRight(this.right);
      navbarStore.setSearch(true);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
    },
  }
}
</script>  