import { defineStore } from 'pinia'
import { router } from '@/router';
import { useAlertStore, useNavbarStore} from '@/stores';
import axios from "axios"
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const useAuthStore = defineStore('auth',{
  id : 'auth',
  state: () => ({
      // initialize state from local storage to enable user to stay logged in
      user: null,
      LoggedIn : false,
      url : false,
      ip : '0.0.0.0'
  }),
  actions: {
      /*async login(url, username, password, t, page) {
        const alertStore = useAlertStore();
        let href = 'hyperviseur';
        try {
            //const user = await fetchWrapper.post(url, { username, password });               
            var vm = this;
            var formDataIp = new FormData();           
            var formDataAccess = new FormData();
            formDataIp.append('action', 'getIpaddress');
            formDataIp.append('h', true);
            axios.post(url+'/account.php', formDataIp).then(({ data }) => {
                vm.ip = data;
                formDataAccess.append('ip_address', data);                
                var formData = new FormData();
                formData.append('action', 'authentification');
                formData.append('login', username);
                formData.append('password', password);
                formData.append('h', true);
                axios
                .post(url+'/account.php', formData)
                .then(function (response) { 
                    formDataAccess.append('action', 'addAccess');  
                    formDataAccess.append('h', true);        
                    if(response.data.auth){
                        vm.user = response.data;                    
                        vm.user.t = t;
                        if(page){
                            vm.user.defaultPage = page;
                        }
                        formDataAccess.append('id_user', response.data.id_user);
                        axios
                        .post(url+'/account.php', formDataAccess)
                        .then(function (responseAccess) {
                                console.log(responseAccess.data.message);
                        });   
                        href = vm.user.defaultPage;
                        vm.LoggedIn = true;    
                        vm.url = url;                                    
                        window.location.href = '#/'+href+'/'
                    }else{             
                        alertStore.error('Identifiant ou Mot de passe incorrect');
                        vm.logout();
                    }                               
                })           
                .catch(function (error) {
                        alertStore.error('Erreur Axios : '+error);
                });     
            });            
        } catch (error) {
            alertStore.error(error);
        }
    },*/
    login(url, t, page, data) {
        const alertStore = useAlertStore();
        let href = 'hyperviseur';
        var vm = this;      
        try {
            //const user = await fetchWrapper.post(url, { username, password });               
            var vm = this;           
            vm.user = data.user;     
            vm.user.id_user = data.user.id;                    
            vm.user.token = data.token;             
            vm.user.pages = data.pages;                     
            vm.user.t = t;
            if(page){
                vm.user.defaultPage = page;
                href = vm.user.defaultPage;
            } 
            vm.LoggedIn = true;    
            vm.url = url;                    
            vm.authorizedApp();                
            window.location.href = '#/'+href+'/';                    
        } catch (error) {
            alertStore.error(error);
        }
    },
    async getFingerprint() {
        const fpPromise = await FingerprintJS.load()
        const fp = fpPromise.get()
        return fp
    },
    logout() {
        this.user = null;
        this.LoggedIn = false;
        this.url = false;
        this.ip = '0.0.0.0';
    },
    getUserId() {
        return this.user.id_user;
    },
    getUser() {
        return this.user;
    },
    isAdmin(){       
        if(this.user.role == 1){
            return true;
        }
        return false;
    },
    getNav(module){
        if(module){
            return this.user.pages[module];
        }
        return false;
    },
    isLoggedIn(){
        return this.LoggedIn;
    },
    getIp() {
        return this.ip;
    },
    authorizedIp(path){  
        //const user = await fetchWrapper.post(url, { username, password });   
        if(path != '/noip/'){
            if(this.url != false){
                var formData = new FormData();
                formData.append('action', 'authorizedIp');
                formData.append('ip', this.ip);
                formData.append('h', true);
                 axios
                .post(this.url+'/account.php', formData)
                .then(function (response) {    
                    if(!response.data.auth){
                        window.location.href = '#/noip/'
                    }
                });
            }else{
                this.logout();
            }
   
        }      
    },
    async authorizedRoute(path){
        const alertStore = useAlertStore();
        var vm = this;
        if(path == '/' || path == '' || path == '/noip/' || path == '/404/') return true;
        if(this.url != false){
            try {
                const config = {
                  headers: { Authorization: `Bearer ${vm.user.token.token}` }
                };
                const bodyParameters = {
                  route: path
                };
                const response = await axios.post(this.url+'/user/authorized', bodyParameters, config);
                return response.data.auth;
              } catch (error) {
                alertStore.error('Accès refusé');
                return false;
              }
        }else{
            this.logout();          
        }       
    },
    async authorizedApp(){
        const alertStore = useAlertStore();
        const navbarStore = useNavbarStore();
        var vm = this;
        try {
            const config = {
                headers: { Authorization: `Bearer ${vm.user.token.token}` }
            };
            const response = await axios.get(vm.url+'/user/'+vm.user.id_user+'/apps', config);  
            navbarStore.setSearchSelectList(response.data);
        } catch (error) {
            console.log(error)
            alertStore.error('Accès refusé');
            return false;
        }       
    }
  },
  persist: {
    storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
  },
});