<template> 
  <v-dialog width="500" v-model="dialog">
    <template v-slot:default="{ isActive }">
      <v-card :title="textToDelete" v-if="dialogType=='delete'">
        <v-card-text v-if="alertDelete.length > 0">
          {{ alertDelete }}
        </v-card-text>
        <v-card-text v-else>
          Êtes-vous sur de vouloir supprimer cet élément ?
        </v-card-text>

        <v-card-actions v-if="alertDelete.length > 0">
          <v-spacer></v-spacer>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="alertDelete='';dialog = false;"
          >
            Fermer
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Non
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="deleteCamera()"
          >
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card :title="textToAdd" v-if="dialogType=='add'">
        <v-card-text v-if="alertAdd.length > 0">
          {{ alertAdd }}
        </v-card-text>
        <v-card-text v-else>
          <v-row v-for="elt in itemToAdd">
            <v-col cols="12">
              <v-text-field
                v-model="elt.value"
                :label="elt.title"
                variant="underlined"
                v-if="elt.type=='text'"
              ></v-text-field>
              <v-select
                v-model="elt.value"
                :clearable="elt.multiple"
                :chips="elt.multiple"
                :label="elt.title"
                :items="$data[elt.data]"
                :multiple="elt.multiple"
                variant="underlined"               
                item-title="name"
                item-value="id"
                v-if="elt.type=='select'"
              >
                <template v-slot:item="{ props, item }" v-if="elt.subtitle">
                  <v-list-item v-bind="props" :subtitle="item.raw.subtitle"></v-list-item>
                </template>
              </v-select>
              <v-radio-group
                v-model="elt.value"
                inline
                v-if="elt.type=='radio'"
              >
                <template v-slot:label>
                  <div>{{ elt.title }}</div>
                </template>
                <v-radio
                  label="Oui"
                  value="1"
                ></v-radio>
                <v-radio
                  label="Non"
                  value="0"
                  class="ml-5"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>       
        </v-card-text>

        <v-card-actions v-if="alertAdd.length > 0">
          <v-spacer></v-spacer>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="alertAdd='';dialog = false;"
          >
            Fermer
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="addCamera()"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  <v-container fluid class="page-content">
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-video-plus"></v-icon> &nbsp;
        Caméras

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          label="Search"
          single-line
          flat
          hide-details
          variant="solo-filled"
        ></v-text-field>
        <v-btn
          prepend-icon="mdi-plus"
          @click="addDialog()"
          class="ml-5"
        >
          <template v-slot:prepend>
            <v-icon color="success"></v-icon>
          </template>
          Ajouter
        </v-btn> 
      </v-card-title>

      <v-divider></v-divider>
      <v-data-table 
        v-model:search="search" 
        :items="cameras"
        :headers="headers"
        item-key="id"
        items-per-page="30"        
        :loading="loadingData"
        loading-text="Chargement... Veuillez patienter svp">
        <template v-slot:item.factory="{ columns, item }">
          <v-select
            v-model="item.factory"
            :items="factoryList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            @update:modelValue="item.updated = true"
            v-if="isAdmin"
          ></v-select>
          <v-text-field
            v-model="item.factoryName"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>        
        <template v-slot:item.name="{ columns, item }">
          <v-text-field
            v-model="item.name"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.name"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>   
        <template v-slot:item.brand="{ columns, item }">
          <v-select
            v-model="item.brand"
            :items="brandList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            @update:modelValue="item.updated = true"
            v-if="isAdmin"
          ></v-select>
          <v-text-field
            v-model="item.brandName"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>                  
        <template v-slot:item.model="{ columns, item }">
          <v-text-field
            v-model="item.model"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.model"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.port="{ columns, item }">
          <v-text-field
            v-model="item.port"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.port"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.port_ws="{ columns, item }">
          <v-text-field
            v-model="item.port_ws"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.port_ws"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.port_api="{ columns, item }">
          <v-text-field
            v-model="item.port_api"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.port_api"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.ip="{ columns, item }">
          <v-text-field
            v-model="item.ip"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.ip"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.users="{ columns, item }">
          <v-select
            v-model="item.users"
            :items="userList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            clearable=true
            chips=true
            multiple=true
            @update:modelValue="item.updated = true"
            v-if="isAdmin"
          ></v-select>
          <v-select
            v-model="item.users"
            :items="userList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            chips=true
            multiple=true
            v-else
          ></v-select>
        </template>
        <template v-slot:item.profils="{ columns, item }">
          <v-select
            v-model="item.profils"
            :items="profilList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            clearable=true
            chips=true
            multiple=true
            @update:modelValue="item.updated = true"
            v-if="isAdmin"
          ></v-select>
          <v-select
            v-model="item.profils"
            :items="profilList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            chips=true
            multiple=true
            v-else
          ></v-select>
        </template>
        <template v-slot:item.api="{ columns, item }">
          <v-select
            v-model="item.api"
            :items="apiList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            @update:modelValue="item.updated = true"
            v-if="isAdmin"
          ></v-select>
          <v-text-field
            v-model="item.apiName"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.ptz="{ columns, item }">
          <v-text-field
            v-model="item.ptz"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.ptz"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.date_added="{ columns, item }">
          <v-text-field
            v-model="item.date_added"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.date_added"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>           
        <template v-slot:item.actions="{ item }">
          <v-icon
            size="large"
            class="me-2"
            @click="saveItem(item)"
            v-if="item.updated"
          >
            mdi-check-bold
          </v-icon>
          <v-icon
            size="medium"
            @click="cancelItem(item)"
            v-if="item.updated"
          >
            mdi-cancel
          </v-icon>
          <v-icon
            size="large"
            class="me-2"
            @click="deleteDialog(item)"
          >
            mdi-close
          </v-icon>
        </template>       
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.successHint >>> .v-messages{
  text-align: left !important;
  color: #1c9500 !important;
  top : -3px;
}
.errorHint >>> .v-messages{
  text-align: left !important;
  color: #f00 !important;
  top : -3px;
}
</style>
<script>
import axios from "axios";
import moment from 'moment';
import { useNavbarStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useAuthStore } from '@/stores';

export default {
  props : {
  },
  data () {
    return {
      idAdmin : false,
      title : 'Administration',
      tabs: [],
      tab : null,
      headers : [ 
        { title: 'Id', value: 'id', align : 'center' },
        { title: 'Site', value: 'factory', align : 'center' },
        { title: 'Nom', value: 'name' , align : 'center'},
        { title: 'Marque', value: 'brand', align : 'center' },
        { title: 'Référence', value: 'model', align : 'center' },
        { title: 'Port HTTP', value: 'port', align : 'center'},
        { title: 'Port WS', value: 'port_ws', align : 'center'},
        { title: 'Port API', value: 'port_api', align : 'center'},
        { title: 'Ip', value: 'ip', align : 'center'},
        { title: 'Utilisateurs', value: 'users', align : 'center'},
        { title: 'Profils', value: 'profils', align : 'center'},
        { title: 'API', value: 'api', align : 'center'},
        { title: 'PTZ', value: 'ptz', align : 'center'},
        { title: 'Ajouté le', value: 'date_added' },
        { title: 'Action', key: 'actions', align : 'end' },      ],      
      cameras : [],
      search: '',
      factoryList : [],
      brandList : [],
      userList : [],
      profilList : [],
      apiList : [],
      loadingData : true,
      dialog : false,
      textToDelete : '',
      itemToDelete : [],
      alertDelete : '',
      textToAdd : '',
      itemToAdd : [],
      alertAdd : '',
      dialogType : '',
      formCamera : [
       {title : 'Id', value : '', visible : false, type : ''},
       {title : 'Site', value : 0, visible : true, type : 'select', multiple : false, data : 'factoryList', subtitle : false},
       {title : 'Nom', value : '', visible : true, type : 'text'},
       {title : 'Marque', value : 0, visible : true, type : 'select', multiple : false, data : 'brandList', subtitle : false},
       {title : 'Référence', value : '', visible : true, type : 'text'},
       {title : 'Port HTTP', value : '', visible : true, type : 'text'},
       {title : 'Port WS', value : '', visible : true, type : 'text'},
       {title : 'Port API', value : '', visible : true, type : 'text'},
       {title : 'Ip', value : '', visible : true, type : 'text'},
       {title : 'Utilisateurs', value : '', visible : true, type : 'select', multiple : true, data : 'userList'},
       {title : 'Profils', value : '', visible : true, type : 'select', multiple : true, data : 'profilList'},
       {title : 'API', value : '', visible : true, type : 'select', multiple : false, data : 'apiList'},
       {title : 'PTZ', value : '', visible : true, type : 'radio'},
       {title : 'Date achat', value : '', visible : true, type : 'text'},] ,
    }
  },  
  computed: {    
    /*ready:  () => {
      this.init();
    },*/
  },
  watch : {
    dialog(){
      if(this.dialog == false){
        this.textToDelete = '';
        this.itemToDelete = [];
        this.alertDelete = '';
        this.textToAdd = '';
        this.itemToAdd = [];
        this.alertAdd = '';
        this.dialogType = '';
      }
    } 
  },
  mounted() {
    this.getBrandList();
    this.getFactoryList();
    this.getUserList();
    this.getProfilList();
    this.getApiList();
    this.init();
  },
  methods: {
    getFactoryList(){
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydrowebs');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {    
          console.log(response)
            if(response.data){              
              vm.factoryList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },  
    getBrandList(){
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getBrands');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
          console.log(response)
            if(response.data){              
              vm.brandList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },   
    getUserList(){
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getUsers');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
          console.log(response)
            if(response.data){              
              vm.userList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },
    getProfilList(){
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getProfils');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
          console.log(response)
            if(response.data){              
              vm.profilList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },   
    getApiList(){
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getApi');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
          console.log(response)
            if(response.data){              
              vm.apiList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },
    init(){
      const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      const authStore = useAuthStore();
      this.isAdmin = authStore.isAdmin();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(null);
      navbarStore.setRight(null);
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getCameras');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
            if(response.data){
              console.log(response.data);
              for (let i = 0; i < response.data.length; i++) {
                vm.cameras.push({
                  id : response.data[i].id, 
                  name : response.data[i].name,
                  brand : response.data[i].brand,
                  brandName : response.data[i].brandName,
                  model : response.data[i].model,
                  date_added : response.data[i].date_added,
                  factory : response.data[i].factory,
                  factoryName : response.data[i].factoryName,
                  port : response.data[i].port,
                  port_ws : response.data[i].port_ws,
                  port_api : response.data[i].port_api,
                  ip : response.data[i].ip, 
                  users : response.data[i].users,
                  profils : response.data[i].profils,
                  api : response.data[i].api, 
                  apiName : response.data[i].apiName,
                  ptz : response.data[i].ptz, 
                  original : {
                    name : response.data[i].name,
                    brand : response.data[i].brand,
                    model : response.data[i].model,
                    date_added : response.data[i].date_added,
                    factory : response.data[i].factory,
                    port : response.data[i].port,
                    port_ws : response.data[i].port_ws,
                    port_api : response.data[i].port_api,
                    ip : response.data[i].ip, 
                    users : response.data[i].users,
                    profils : response.data[i].profils,
                    api : response.data[i].api, 
                    ptz : response.data[i].ptz, 
                  }                 
                });
              }
              vm.loadingData = false;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }               
    },    
    saveItem(item){
      const alertStore = useAlertStore();
      var index = this.cameras.indexOf(item);
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'setCamera');
        console.log(item);
        formData.append('camera', JSON.stringify(item));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
            if(response.data){
              vm.cameras[index].original.name = item.name;
              vm.cameras[index].original.brand = item.brand;
              vm.cameras[index].original.model = item.model;
              vm.cameras[index].original.date_added = item.date_added;
              vm.cameras[index].original.factory = item.factory;
              vm.cameras[index].original.port = item.port;
              vm.cameras[index].original.port_ws = item.port_ws;
              vm.cameras[index].original.port_api = item.port_api;
              vm.cameras[index].original.ip = item.ip;
              vm.cameras[index].original.users = item.users;
              vm.cameras[index].original.profils = item.profils;
              vm.cameras[index].original.api = item.api;
              vm.cameras[index].original.ptz = item.ptz;
              vm.cameras[index].updated = false;
              alertStore.success('Caméra mise à jour');
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }      
    },
    addCamera(){
      const alertStore = useAlertStore();
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'addCamera');
        formData.append('data', JSON.stringify(this.itemToAdd));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
            if(response.data.statut == true){
              vm.alertAdd = "Insertion réussie";
              var idListFactory = vm.factoryList.map(el => el.id);
              var idListBrand = vm.brandList.map(el => el.id);
              var idListApi = vm.apiList.map(el => el.id);
              let indexFactory = idListFactory.indexOf(vm.itemToAdd[1].value);
              let indexBrand = idListBrand.indexOf(vm.itemToAdd[3].value);
              let indexApi = idListApi.indexOf(vm.itemToAdd[11].value);
              vm.cameras.push({
                id : response.data.id, 
                  name : vm.itemToAdd[2].value,
                  brand : vm.itemToAdd[3].value,
                  brandName : vm.brandList[indexBrand].name,
                  model : vm.itemToAdd[4].value,
                  date_added : vm.itemToAdd[8].value,
                  factory : vm.itemToAdd[1].value,
                  factoryName : vm.factoryList[indexFactory].name,
                  port : vm.itemToAdd[5].value,
                  port_ws : vm.itemToAdd[6].value,
                  port_api : vm.itemToAdd[7].value,
                  ip : vm.itemToAdd[8].value, 
                  users : vm.itemToAdd[9].value,
                  profils : vm.itemToAdd[10].value,
                  api : vm.itemToAdd[11].value,
                  apiName : vm.apiList[indexApi].name,
                  ptz : vm.itemToAdd[12].value,
                  original : {
                    name : vm.itemToAdd[2].value,
                    brand : vm.itemToAdd[3].value,
                    model : vm.itemToAdd[4].value,
                    date_added : vm.itemToAdd[8].value,
                    factory : vm.itemToAdd[1].value,
                    port : vm.itemToAdd[5].value,
                    port_ws : vm.itemToAdd[6].value,
                    port_api : vm.itemToAdd[7].value,
                    ip : vm.itemToAdd[8].value, 
                    users : vm.itemToAdd[9].value,
                    profils : vm.itemToAdd[10].value,
                    api : vm.itemToAdd[11].value,
                    ptz : vm.itemToAdd[12].value,
                  }   
              });   
            }else{
                vm.alertAdd = response.data.message;                           
            }          
            /*vm.$nextTick(() => { 
              setTimeout(function(){vm.dialog = false;vm.alertAdd = '';}, 2500);    
            });*/
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },
    deleteCamera(){
      const alertStore = useAlertStore();
      var index = 0;
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'deleteCamera');
        formData.append('data', JSON.stringify(this.itemToDelete));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) {    
            if(response.data.statut){
              index = vm.cameras.indexOf(vm.itemToDelete);
              vm.cameras.splice(index, 1);
              vm.alertDelete = "Suppression réussie";
            }else{             
              vm.alertDelete = "Supression échouée";
            }          
            /*vm.$nextTick(() => { 
              setTimeout(function(){vm.dialog = false;vm.alertDelete = '';}, 2500);    
            });*/
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },    
    cancelItem(item){
      var index = this.cameras.indexOf(item);
      this.cameras[index].name = this.cameras[index].original.name;
      this.cameras[index].brand = this.cameras[index].original.brand;
      this.cameras[index].model = this.cameras[index].original.model;
      this.cameras[index].date_added = this.cameras[index].original.date_added;
      this.cameras[index].factory = this.cameras[index].original.factory;
      this.cameras[index].port = this.cameras[index].original.port;
      this.cameras[index].port_ws = this.cameras[index].original.port_ws;
      this.cameras[index].port_api = this.cameras[index].original.port_api;
      this.cameras[index].ip = this.cameras[index].original.ip;
      this.cameras[index].users = this.cameras[index].original.users;
      this.cameras[index].profils = this.cameras[index].original.profils;
      this.cameras[index].api = this.cameras[index].original.api;
      this.cameras[index].ptz = this.cameras[index].original.ptz;
      this.cameras[index].updated = false;
    },
    addDialog(){
      this.dialogType = 'add';
      this.textToAdd = 'Ajout caméra'; 
      this.dialog = true;
      this.formatList = []; 
      this.itemToAdd = this.formCamera;   
    },
    deleteDialog(item){
      this.dialogType = 'delete';
      this.textToDelete = 'Suppression caméra'; 
      this.dialog = true;
      this.itemToDelete = item;
    },
  },
};
</script>