<template>
  <v-container fluid class="page-content">
    <v-window v-model="windowsTab">
      <v-window-item class="px-2 py-2" style="width:96%;margin:0 auto;">       
        <vue3-datatable
                :rows="filteredRows"
                :columns="cols"
                :loading="loading"
                :totalRows="total_rows"
                :isServerMode="false"
                :pageSize="params.pagesize"
                :sortable="true"
                :columnFilter="true"
                class="column-filter"
                :sortColumn="params.sort.field"
                :sortDirection="params.sort.type"
                @change="changeServer"
                :stickyHeader="true"
                height='100%'
                :columnFilterLang="{no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null'}"
        >
        </vue3-datatable>      
      </v-window-item>
      <v-window-item class="px-2 py-2" style="width:96%;margin:0 auto;">    
        <FullCalendar :options="calendarOptions">
          <template v-slot:eventContent='arg'>
            <b :class="'text-'+arg.event.backgroundColor">{{ new Date(arg.event.start).toLocaleTimeString() }} : {{ arg.event.extendedProps.apps }} - {{ arg.event.title }} {{  console.log(arg.event) }}</b>
          </template>
        </FullCalendar>
      </v-window-item>        
    </v-window>            
  </v-container>
</template>
<script>
const resizeObserver = new ResizeObserver(entries => {
   // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
   window.requestAnimationFrame(() => {
     if (!Array.isArray(entries) || !entries.length) {
       return;
     }
     // your code
   });
});
//var apiFileUrl = "api_domms.php";
//import axios from "axios";
import { useNavbarStore } from '@/stores';
import { useAuthStore } from '@/stores';
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
  components: {
    Vue3Datatable,
    FullCalendar 
  },
  data () {
    const srcs = {
        1: require('@/assets/icons/barrage.svg'),
        2: require('@/assets/icons/turbine.svg'),
        3: require('@/assets/icons/maisonVerte.svg'),
      }
    return {
      title : 'Historique',
      tabs: [{id : 0, value : 0, hideMobile : 0, icon : 'mdi-view-list'},
          {id : 1, value : 1, hideMobile : 1, icon : 'mdi-calendar-month'}],
      tab : 0,
      right : 'tabs',
      stats: [],
      search : true,
      button : false,  
      buttons : false,  
      loading : true,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        weekends: false,
        locale: 'fr', 
        editable: true, 
        eventLimit: true,
        dayMaxEvents : 3,
        events: [
          { title: 'Meeting', start: new Date() }
        ],

      },
      cols: [
          { field: 'user', title: 'Utilisateur' },
          { field: 'start', title: 'Date', type: 'date' },
          { field: 'title', title: 'Ressources' },
          { field: 'value', title: 'Valeur', type: 'number' },
          { field: 'old', title: 'Ancienne', type: 'number' },
          { field: 'zone', title: 'Localisation' },
          {
            align: 'start',
            field: 'apps',
            sortable: true,
            title: 'Site',
            hide : true,
          },
        ],
         rows: [],
         total_rows: 10,
         loading: false,
         params: {
            page: 1,
            pagesize: 10,
            sort: { field: 'user', type: 'desc' },
            filter: { field: 'apps', value: '' },
        },
        headers: [
          { key: 'user', title: 'Utilisateur' },
          { key: 'start', title: 'Date' },
          { key: 'title', title: 'Ressources' },
          { key: 'value', title: 'Valeur' },
          { key: 'old', title: 'Ancienne' },
          { key: 'zone', title: 'Localisation' },
          {
            align: 'start',
            key: 'apps',
            sortable: true,
            title: 'Site',
          },
        ],
        rows: [
          {
            apps: 'Mourlasse',
            start: '2023-01-02 05:00:54',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },
          {
            apps: 'Mourlasse',
            start: '2023-01-03',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Eric Digney',
            zone: 'D',
            role: 'mdi-account-hard-hat',
          },{
            apps: 'Mourlasse',
            start: '2023-01-04',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'John Doe',
            zone: 'D',
            role: 'mdi-account-search',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },
          {
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Eric Digney',
            zone: 'D',
            role: 'mdi-account-hard-hat',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'John Doe',
            zone: 'D',
            role: 'mdi-account-search',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },
          {
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Eric Digney',
            zone: 'D',
            role: 'mdi-account-hard-hat',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'John Doe',
            zone: 'D',
            role: 'mdi-account-search',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '5000',
            old: '400',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },
          {
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Eric Digney',
            zone: 'D',
            role: 'mdi-account-hard-hat',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'John Doe',
            zone: 'D',
            role: 'mdi-account-search',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '5000',
            old: '400',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },{
            apps: 'Mourlasse',
            start: '2024-06-25 10:00:00',
            title: 'Turbine',
            value: '1000',
            old: '900',
            user: 'Dubois Cyril',
            zone: 'D',
            role: 'mdi-account-tie',
          },
        ],
        filteredRows : []
    }
  },
  computed: {   
    windowsTab:  () => {
      const navbarStore = useNavbarStore();
      return navbarStore.getTab();
    },
    selectedApps:  () => {
      const navbarStore = useNavbarStore();
      return navbarStore.getSearchSelected();
    },
  },
  mounted() {
    this.init();
  },
  watch : {
    selectedApps : function(){
      this.updateFilteredRow();
    },
  },
  methods: {
    init(){
      const authStore = useAuthStore();
      const navbarStore = useNavbarStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(this.tab);
      navbarStore.setRight(this.right);
      navbarStore.setSearch(this.search);
      authStore.authorizedApp();
      navbarStore.setButton(this.button);
      navbarStore.setButtons(this.buttons);
      this.updateFilteredRow();
      this.loading = false;
      //calendarOptions
    },
    updateFilteredRow(){
      const navbarStore = useNavbarStore();
      const apps = navbarStore.getSearchSelectList();
      const idApps = apps.map(el => el.id); 
      var selectedAppsName = [];
      for (const key in this.selectedApps) {
        if (this.selectedApps.hasOwnProperty.call(this.selectedApps, key)) {
          const element = this.selectedApps[key];         
          var index = idApps.indexOf(element);
          if(index >= 0){
            selectedAppsName.push(apps[index].name);
          } 
        }
      }
      if(selectedAppsName.length > 0){
        this.filteredRows = this.rows.filter(item => selectedAppsName.includes(item['apps']));
      }else{
        this.filteredRows = this.rows;
      } 
      this.calendarOptions.events = this.filteredRows;
    },
    changeServer(data) {
        this.params.current_page = data.current_page;
        this.params.pagesize = data.pagesize;
        this.params.sort.field = data.sort_column;
        this.params.sort.type = data.sort_direction;
        this.filterAndSortRows(data.column_filters);
    },
    filterAndSortRows(filters){
      this.filteredRows = this.rows;
      for (const key in filters) {
        if (filters.hasOwnProperty.call(filters, key)) {
          const element = filters[key];
          if(element.value){  
            if(element.condition == "contain"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field].toLowerCase().includes(element.value.toLowerCase()));
            }else if(element.condition == "not_contain"){
              this.filteredRows = this.filteredRows.filter(item => !item[element.field].toLowerCase().includes(element.value.toLowerCase()));
            }else if(element.condition == "equal"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field].toLowerCase() === element.value.toLowerCase());
            }else if(element.condition == "not_equal"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field].toLowerCase() !== element.value.toLowerCase());
            }else if(element.condition == "start_with"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field].toLowerCase().startsWith(element.value.toLowerCase()));
            }else if(element.condition == "end_with"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field].toLowerCase().endsWith(element.value.toLowerCase()));
            }else if(element.condition == "greater_than"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] > element.value);
            }else if(element.condition == "greater_than_equal"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] >= element.value);
            }else if(element.condition == "less_than"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] < element.value);
            }else if(element.condition == "less_than_equal"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] <= element.value);
            }else if(element.condition == "is_null"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] === null);
            }else if(element.condition == "is_not_null"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] !== null);
            } 
          }else{
            if(element.condition == "is_null"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] === null);
            }else if(element.condition == "is_not_null"){
              this.filteredRows = this.filteredRows.filter(item => item[element.field] !== null);
            } 
          }   
        }
      }     
      this.filteredRows = this.filteredRows.sort((a, b) => {
        const idList = this.cols.map(el => el.field);
        const index = idList.indexOf(this.params.sort.field);
        if (this.cols[index].type == 'number') {
          if(this.params.sort.type == 'asc'){
            return a[this.params.sort.field] - b[this.params.sort.field];
          }else{
            return b[this.params.sort.field] - a[this.params.sort.field];
          }          
        } else if (this.cols[index].type == 'date') {
          if(this.params.sort.type == 'asc'){
            return new Date(a[this.params.sort.field]) - new Date(b[this.params.sort.field]);
          }else{
            return new Date(b[this.params.sort.field]) - new Date(a[this.params.sort.field]);
          }            
        }else {
          if(this.params.sort.type == 'asc'){
            return a[this.params.sort.field].localeCompare(b[this.params.sort.field]);
          }else{
            return b[this.params.sort.field].localeCompare(a[this.params.sort.field]);
          } 
        }
      });
    }
  }
}
</script> 
<style>
.bh-datatable tbody tr:nth-child(even) {
      background-color: #ececec;
}

.bh-datatable tbody tr:nth-child(odd) {
      background-color: #fff;
}

.bh-datatable tbody tr:hover {
      background-color: #CCC !important;
}
</style>