<template>   
  <v-dialog
      v-model="dialog"
      :width="(isMobile)?'80%':'60%'"
    >
      <v-card>
        <v-toolbar      
            class="border border-b-sm"
            color="white"
          >
            <v-icon
                :icon="dialogTitle.icon"
                size="large"
                :color="dialogTitle.color"
                class="my-2 ml-5 mr-3"
            ></v-icon>
            <v-divider :thickness="3" color="error" vertical></v-divider>
            <v-toolbar-title class="text-h6">             
              <span :color="(dialogTitle.color != '')?dialogTitle.color:'grey-darken-3'">{{ dialogTitle.title }}</span>         
            </v-toolbar-title>
            <template v-slot:append>      
              <v-btn
                  icon="mdi-close"
                  density="comfortable"
                  variant="plain"
                  @click="dialog = false"
              ></v-btn>
            </template>
          </v-toolbar>

        <v-card-text>
          <v-list-item :key="`dialog-${index}`" v-for="(text,index) in dialogContent">
              <v-list-item-title class="my-label">{{text}}
              </v-list-item-title>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  <v-container fluid class="page-content">
        <v-window v-model="windowsTab">
          <v-window-item class="px-2 py-2">
            <v-expansion-panels variant="accordion">
              <v-expansion-panel
                v-for="hydroweb in hydrowebs"
                :key="hydroweb.id"
                :bg-color="(hydroweb.com == 1)?'white':'grey-lighten-3'"                
              >
                <v-expansion-panel-title :class="(isMobile)?'px-1 pt-4 pb-1':'pa-3'">
                  <template v-slot:default="{ expanded }">
                    <v-row no-gutters>
                          <v-col class="d-flex justify-start" :cols="(isMobile)?'1':'1'">  
                            <v-badge text-color="white" color="orange-darken-1" :content="hydroweb.alarme" v-if="hydroweb.alarme > 0">
                              <v-btn color="orange-darken-1" size="x-small" class="flash" @click.stop="displayDialog(hydroweb.id,'Alarme')" icon="mdi-alarm-light"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="x-small" icon="mdi-alarm-light" variant="text" v-else>              
                            </v-btn> 
                          </v-col>
                          <v-col class="d-flex justify-start" :cols="(isMobile)?'1':'1'">  
                            <v-badge text-color="white" color="red-darken-1" :content="hydroweb.default" v-if="hydroweb.default > 0">
                              <v-btn color="red-darken-1" size="x-small" class="flash" @click.stop="displayDialog(hydroweb.id,'Défaut')" icon="mdi-alert"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="x-small" icon="mdi-alert" variant="text" v-else>
                            </v-btn> 
                          </v-col>
                      <v-col class="d-flex justify-start pl-5 pt-2" :cols="(isMobile)?'4':'4'">
                        {{ hydroweb.name }}
                      </v-col>
                      <v-col class="d-flex justify-start pl-0 pt-2" cols="3">
                          <v-icon
                            icon="mdi-lightning-bolt"
                            start
                            size="small"
                          ></v-icon>
                          {{ hydroweb.conso_elec }} Kwh
                      </v-col>
                      <v-col class="d-flex justify-start pl-0 pt-2" cols="3">
                          <v-icon
                            icon="mdi-water"
                            start
                            size="small"
                          ></v-icon>
                          {{ hydroweb.conso_eau }} l/h
                      </v-col>
                      <!--<v-col
                        class="text-grey"
                        cols="8"
                      >
                        <v-fade-transition leave-absolute>
                          <span
                            v-if="expanded"
                            key="0"
                          >
                            Enter a name for the trip
                          </span>
                          <span
                            v-else
                            key="1"
                          >
                            hihi
                          </span>
                        </v-fade-transition>
                      </v-col>-->
                    </v-row>
                  </template>
                  <template v-slot:actions>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>               
                  <v-row>
                        <v-col md="12" sm="12" class="pa-0 ma-0">
                          <highcharts :options="hydroweb.chart"></highcharts>
                        </v-col>                            
                  </v-row>       
                    <v-bottom-navigation :key="'navBottomList-'+hydroweb.id" absolute horizontal grow style="left:0px;width: calc((100%) - 0px);">                   
                      <v-btn icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('journal',hydroweb.id)">
                        <v-icon>mdi-comment-alert</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('historique',hydroweb.id)">
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>                 
                      <v-btn icon @click="redirect('statistiques',hydroweb.id)">
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('rapport',hydroweb.id)">
                        <v-icon>mdi-notebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </v-bottom-navigation>                     
                </v-expansion-panel-text>              
              </v-expansion-panel>
            </v-expansion-panels>       
          </v-window-item>
          <v-window-item class="px-2 py-2">
           
              <v-row density="compact">
                <v-col
                  v-for="hydroweb in hydrowebs"
                  :key="hydroweb.id"
                  xxl="2" xl="3" lg="4" md="6" sm="12" cols="12"
                >
                  <v-card :loading="loading" class="mx-2 my-2 pb-9">
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <v-toolbar
                      color="green"
                      density="compact"
                      v-if="hydroweb.com == 1"
                    >
                      <!--<v-icon icon="mdi-compare-vertical"></v-icon>-->
                      <v-app-bar-title>{{ hydroweb.name }}</v-app-bar-title>
                      
                            
                      <div style="height:100%;position:relative;" :class="(hydroweb.alarme > 0)?'bg-orange ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydroweb.alarme" v-if="hydroweb.alarme > 0">
                              <v-btn color="orange-lighten-4" size="small" class="flash" @click="displayDialog(hydroweb.id,'Alarme')" icon="mdi-alarm-light"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small"  icon="mdi-alarm-light" variant="text" v-else>
                            </v-btn>                      
                      </div>
                      <div style="height:100%;" :class="(hydroweb.default > 0)?'bg-red ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydroweb.default" v-if="hydroweb.default > 0">
                              <v-btn color="red-lighten-4" size="small" class="flash" @click="displayDialog(hydroweb.id,'Défaut')" icon="mdi-alert"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small" icon="mdi-alert" variant="text" v-else>
                            </v-btn> 
                      </div>
                    </v-toolbar>
                    <v-toolbar
                      color="red"
                      density="compact"
                      class="flash"
                      v-else
                    >
                      <v-toolbar-title>{{ hydroweb.name }}</v-toolbar-title>

                      <v-btn icon>
                        <v-icon>mdi-compare-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col md="6" sm="6">
                          <v-card >
                            <v-row>
                              <v-col cols="3" class="py-6 pr-0 text-center bg-yellow-darken-4" >
                                <v-icon
                                  icon="mdi-lightning-bolt"
                                  start
                                  size="small"
                                ></v-icon>
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="6" class="py-6">
                                {{ hydroweb.conso_elec }} 
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="3" class="py-6 pr-0">
                                Kwh
                              </v-col>                         
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col md="6" sm="6">
                          <v-card>
                            <v-row>
                              <v-col cols="3" class="py-6 pr-0 text-center bg-cyan-darken-3" >
                                <v-icon
                                  icon="mdi-water"
                                  start
                                  size="small"
                                ></v-icon>
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="6" class="py-6">
                                {{ hydroweb.conso_eau }} 
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="3" class="py-6 pr-0">
                                l/h
                              </v-col>                         
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col md="12" sm="12">
                          <highcharts :options="hydroweb.chart"></highcharts>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-bottom-navigation :key="'navBottomSquare-'+hydroweb.id" absolute horizontal grow style="left:0px;width: calc((100%) - 0px);">                   
                      <v-btn icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('journal',hydroweb.id)">
                        <v-icon>mdi-comment-alert</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('historique',hydroweb.id)">
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>                 
                      <v-btn icon @click="redirect('statistiques',hydroweb.id)">
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('rapport',hydroweb.id)">
                        <v-icon>mdi-notebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </v-bottom-navigation>   
                  </v-card>
                </v-col>
              </v-row>
          </v-window-item>
          <v-window-item>
            <v-row class="mb-0">
              <v-col v-for="item in legendMapItems" :key="item.text" cols="auto">
                <v-icon :color="item.color" :icon="item.icon" size="small"></v-icon>
                {{ item.text }}
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col xxl="9" xl="9" lg="8" md="7" cols="12">               
                <VMap style="height:1000px" :center="mapCenter" zoom="17">
                  <VMapOsmTileLayer />
                  <VMapZoomControl />
                  <VMapAttributionControl />
                    <VMapPinMarker
                      :latlng="[hydroweb.latitude,hydroweb.longitude]"
                      icon-color="#000000"
                      :icon-background-color="(hydroweb.com)?(hydroweb.default)?'#E57373':'#66BB6A':'#616161'"
                      :icon-placeholder-color="(hydroweb.com)?(hydroweb.alarme)?'#FFCC80':'#C8E6C9':'#E0E0E0'"
                      @click="selectedIndex=index"
                      v-for="(hydroweb,index) in hydrowebs" :key="hydroweb.id"
                    >
                      {{ hydroweb.shortName }}
                    </VMapPinMarker>
                </VMap>
              </v-col>
              <v-col xxl="3" xl="3" lg="4" md="5" cols="12">
                <v-card :loading="loading" class="mx-2 my-0 pb-9">
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <v-toolbar
                      color="green"
                      density="compact"
                      v-if="hydrowebs[selectedIndex].com == 1"
                    >
                      <!--<v-icon icon="mdi-compare-vertical"></v-icon>-->
                      <v-app-bar-title>{{ hydrowebs[selectedIndex].name }}</v-app-bar-title>
                      <div style="height:100%;position:relative;" :class="(hydrowebs[selectedIndex].alarme > 0)?'bg-orange ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydrowebs[selectedIndex].alarme" v-if="hydrowebs[selectedIndex].alarme > 0">
                              <v-btn color="orange-lighten-4" size="small" class="flash" @click="displayDialog(hydrowebs[selectedIndex].id,'Alarme')" icon="mdi-alarm-light"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small"  icon="mdi-alarm-light" variant="text" v-else>
                            </v-btn>                      
                      </div>
                      <div style="height:100%;" :class="(hydrowebs[selectedIndex].default > 0)?'bg-red ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydrowebs[selectedIndex].default" v-if="hydrowebs[selectedIndex].default > 0">
                              <v-btn color="red-lighten-4" size="small" class="flash" @click="displayDialog(hydrowebs[selectedIndex].id,'Défaut')" icon="mdi-alert"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small" icon="mdi-alert" variant="text" v-else>
                            </v-btn> 
                      </div>
                    </v-toolbar>
                    <v-toolbar
                      color="red"
                      density="compact"
                      class="flash"
                      v-else
                    >
                      <v-toolbar-title>{{ hydrowebs[selectedIndex].name }}</v-toolbar-title>

                      <v-spacer></v-spacer>
                      COM
                      <v-btn icon>
                        <v-icon>mdi-compare-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col md="6" sm="6">
                          <v-card >
                            <v-row>
                              <v-col cols="3" class="py-6 pr-0 text-center bg-yellow-darken-4" >
                                <v-icon
                                  icon="mdi-lightning-bolt"
                                  start
                                  size="small"
                                ></v-icon>
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="6" class="py-6">
                                {{ hydrowebs[selectedIndex].conso_elec }} 
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="3" class="py-6 pr-0">
                                Kwh
                              </v-col>                         
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col md="6" sm="6">
                          <v-card>
                            <v-row>
                              <v-col cols="3" class="py-6 pr-0 text-center bg-cyan-darken-3" >
                                <v-icon
                                  icon="mdi-water"
                                  start
                                  size="small"
                                ></v-icon>
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="6" class="py-6">
                                {{ hydrowebs[selectedIndex].conso_eau }} 
                              </v-col>
                              <v-divider class="border-opacity-100"  vertical></v-divider>
                              <v-col cols="3" class="py-6 pr-0">
                                l/h
                              </v-col>                         
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col md="12" sm="12">
                          <highcharts :options="hydrowebs[selectedIndex].chart"></highcharts>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-bottom-navigation :key="'navBottomMap-'+hydrowebs[selectedIndex].id" absolute horizontal grow style="left:0px;width: calc((100%) - 0px);">                   
                      <v-btn icon>
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('journal',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-comment-alert</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('historique',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>                 
                      <v-btn icon @click="redirect('statistiques',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('rapport',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-notebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </v-bottom-navigation>   
                  </v-card>                
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>       
  </v-container>
</template>
<script>
var apiFileUrl = "api_domms.php";
//import { createApp } from "vue";
import axios from "axios";
//import Vidle from 'v-idle'
import { VMap, VMapGoogleTileLayer, VMapOsmTileLayer  , VMapZoomControl, VMapAttributionControl, VMapPinMarker } from 'vue-map-ui';
import { useAuthStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useNavbarStore } from '@/stores';
export default {
  components: {
    VMap,
    VMapGoogleTileLayer,
    VMapOsmTileLayer ,
    VMapZoomControl,
    VMapAttributionControl,
    VMapPinMarker,
  },
  props : {
  },
  data () {
    return {
    title : 'Port de la Grande Motte',
    tabs: [{id : 0, value : 0, hideMobile : 0, icon : 'mdi-view-list'},
          {id : 1, value : 1, hideMobile : 1, icon : 'mdi-view-grid'},
          {id : 2, value : 2, hideMobile : 1, icon : 'mdi-map'}],
    tab : 0,
    right : 'searchSelect',
    value: "",
    loading: false,
    mapmode:false,
    dialog:false,
    dialogContent:[],
    hydrowebs : [],
    test : 0,
    intervalId: null,
    dataInit : false,
    timeRefresh : 15000,
    mapCenter : [43.557259, 4.081624],
    selectedIndex : 1,
    legendMapItems : [{icon: 'mdi-map-marker', color:'#66BB6A', text: 'Ok'},
                      {icon: 'mdi-map-marker', color:'#E57373', text: 'Défaut'},
                      {icon: 'mdi-circle', color:'#FFCC80', text: 'Alarme'},
                      {icon: 'mdi-map-marker', color:'#616161', text: 'COM perdue'},                   
                      /*{icon: 'mdi-numeric-9-plus-circle', color:'#C8E6C9', text: 'Valeur de la puissance'},*/],
    dialogTitle : {title : 'Informations', color : 'grey-darken-3', icon : 'mdi-information-box'}
  }
  },  
computed: {
  windowsTab:  () => {
    const navbarStore = useNavbarStore();
    console.log(navbarStore.getTab());
    return navbarStore.getTab();
  },
  isMobile : () => {
    const navbarStore = useNavbarStore();
    return navbarStore.getMobile();
  }
},
mounted() {
    this.init();
    //this.startInterval();
  },
methods: { 
  /*startInterval: function() {
    this.intervalId = setInterval(() => {
      this.getData();
    }, this.timeRefresh);
  },*/
  getData(){
    if(this.dataInit){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      console.log('REFRESH'+Date());
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydrowebByUser');
        formData.append('id', authStore.user.id_user);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {    
            if(response.data){         
              var idListHydroweb = vm.hydrowebs.map(el => el.id);
              for (var id in response.data) {                          
                    var indexHydroweb = idListHydroweb.indexOf(response.data[id].id); 
                    if(response.data[id].hyperviseur[2]){                                                 
                      var idListNiveau = vm.hydrowebs[indexHydroweb].niveauSeries[0].data.map(el => el.x);             
                      for (let index = 0; index < response.data[id].hyperviseur[2].length; index++) {
                        var indexNiveau = idListNiveau.indexOf(response.data[id].hyperviseur[2][index].name); 
                        vm.hydrowebs[indexHydroweb].niveauSeries[0].data[indexNiveau].y = response.data[id].hyperviseur[2][index].value;
                      }
                    }
                    if(response.data[id].hyperviseur[1]){
                      vm.hydrowebs[indexHydroweb].puissanceValue = response.data[id].hyperviseur[1][0].value;
                      vm.hydrowebs[indexHydroweb].puissance.series[0].data[0] = response.data[id].hyperviseur[1][0].value;
                    }
                    
                    var idListProduction = vm.hydrowebs[indexHydroweb].production.map(el => el.label);  
                    if(response.data[id].hyperviseur[3]){                        
                      var indexProduction = idListProduction.indexOf(response.data[id].hyperviseur[3][0].nameType);
                      vm.hydrowebs[indexHydroweb].production[indexProduction].value = response.data[id].hyperviseur[3][0].value
                    }                     
                    vm.hydrowebs[indexHydroweb].com = response.data[id].com
                    vm.hydrowebs[indexHydroweb].alarme = response.data[id].alarme
                    vm.hydrowebs[indexHydroweb].default = response.data[id].default                                     
                }                         
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    }
  },
  init(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(this.tab);
      navbarStore.setRight('tabs');
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
      //const api = this.apiUrl;
      try{
        this.hydrowebs = [{id : 116, name : 'Ponton A', shortName : 'A', latitude : '43.559793', longitude : '4.082189', com : 1, alarme : 0, default : 0, conso_elec : 150, conso_eau : 10}, 
        {id : 117, name : 'Ponton B', shortName : 'B', latitude : '43.559453', longitude : '4.082486', com : 1, alarme : 0, default : 0, conso_elec : 100, conso_eau : 20},
        {id : 3, name : 'Ponton C', shortName : 'C', latitude : '43.559087', longitude : '4.082761', com : 1, alarme : 0, default : 0, conso_elec : 20, conso_eau : 15},
        {id : 4, name : 'Ponton D', shortName : 'D', latitude : '43.558777', longitude : '4.083005', com : 1, alarme : 1, default : 3, conso_elec : 0, conso_eau : 0},
        {id : 5, name : 'Ponton E', shortName : 'E', latitude : '43.558394', longitude : '4.083314', com : 1, alarme : 0, default : 0, conso_elec : 10, conso_eau : 2},
        {id : 6, name : 'Ponton F', shortName : 'F', latitude : '43.557999', longitude : '4.083634', com : 1, alarme : 1, default : 0, conso_elec : 60, conso_eau : 5},
        {id : 7, name : 'Ponton G', shortName : 'G', latitude : '43.557668', longitude : '4.085245', com : 0, alarme : 0, default : 0, conso_elec : 0, conso_eau : 0}];  
        this.hydrowebs.forEach(element => {
          console.log(element.name)
          element.chart = {
                                chart: {
                                    type: 'line',
                                    height : '300px'
                                },

                                title: {
                                    text: ''
                                },

                                credits : {
                                    enabled : false
                                },
                                xAxis: {
                                    gridLineWidth: 1,
                                    labels: {
                                        align: 'left',
                                        x: -7,
                                        y: 15
                                    },
                                    categories: ['00', '02', '04', '06', '08', '10', '12','14', '16', '18', '20', '22']
                                },
                                // the value axis
                                yAxis: [{ // left y axis
                                          title: {
                                              text: 'Kwh'
                                          },
                                          labels: {
                                              align: 'left',
                                              x: -20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                        },
                                        { // right y axis
                                          gridLineWidth: 0,
                                          opposite: true,
                                          title: {
                                              text: 'l/h'
                                          },
                                          labels: {
                                              align: 'right',
                                              x: 20,
                                              y: 5,
                                              format: '{value:.,0f}'
                                          },
                                          showFirstLabel: false
                                      }
                                ],
                                legend: {
                                    align: 'left',
                                    verticalAlign: 'top',
                                    borderWidth: 0
                                },

                                tooltip: {
                                    shared: true,
                                    crosshairs: true
                                },
                                plotOptions: {
                                  line: {
                                      dataLabels: {
                                          enabled: false
                                      },
                                      enableMouseTracking: true
                                  }
                                },

                                series: [ {
                                      name: 'Electricité',
                                      type: 'line',
                                      color : '#F57F17',
                                      yAxis: 0,
                                      data: [100.0, 180.2, 230.1, 270.9, 320.2, 306.4, 309.8, 308.4, 305.5, 290.2,
                                          220.0, 207.8, ]
                                  }, {
                                      name: 'Eau',
                                      type: 'line',
                                      color : '#00838F',
                                      yAxis: 1,
                                      data: [16.0, 18.2, 23.1, 27.9, 32.2, 36.4, 39.8, 38.4, 35.5, 29.2,
                                          22.0, 17.8,]
                                  }]
                              };
        });

      } catch (error) {   
        alertStore.error(error);
      } 
  },
  checkReadyState(checker) {
    if (checker.readyState === 4) {
        if ((checker.status == 200) || (checker.status == 0)) {     
          console.log('OK');
        }
        else {          
          console.log('NOK');
          return;
        }
    }
  },
  testApi:  () => {
    var formData = new FormData();
    //formData.append('action', 'getOldPalette');
    //var vm = this;
    axios
      .post($this.apiUrl+'/'+apiFileUrl, formData)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  displayDialog(id,title){
    const alertStore = useAlertStore();
    var action = '';
    var icon = 'mdi-information-box';
    var color = 'grey-darken-3';
        var vm = this;
    if(title == 'Alarme'){
      action = 'getAlarmeByHydroweb';
      icon = 'mdi-alarm-light';
      color = 'orange-darken-1';
      //TEMPO
      vm.dialog = true;
            vm.dialogTitle.title = title;
            vm.dialogTitle.color = color;
            vm.dialogTitle.icon = icon;     
            vm.dialogContent = [];     
              vm.dialogContent.push('Alarme 1');  
    }else if(title == 'Défaut'){
      action = 'getDefautByHydroweb';
      icon = 'mdi-alert';
      color = 'red-darken-1';
      //TEMP
      vm.dialog = true;
            vm.dialogTitle.title = title;
            vm.dialogTitle.color = color;
            vm.dialogTitle.icon = icon;     
            vm.dialogContent = [];     
              vm.dialogContent.push('Défaut 1');  
    }
     /* try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', action);
        formData.append('id', id);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {         
          if(response.data){
            vm.dialog = true;
            vm.dialogTitle.title = title;
            vm.dialogTitle.color = color;
            vm.dialogTitle.icon = icon;
            vm.dialogContent = [];  
            for (let i = 0; i < response.data.length; i++) {
              vm.dialogContent.push(response.data[i].text);            
            }
          }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });    
      } catch (error) {   
        alertStore.error(error);
      } */

  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  redirect(route,id){
    const navbarStore = useNavbarStore();
    navbarStore.setSearchSelected([id]);
    window.location.href = '#/'+route+'/'; 
  }
},
};
</script>
<style scoped>
.highcharts-figure,
.highcharts-data-table table {
min-width: 310px;
max-width: 500px;
margin: 1em auto;
}

.highcharts-data-table table {
font-family: Verdana, sans-serif;
border-collapse: collapse;
border: 1px solid #ebebeb;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}

.highcharts-data-table caption {
padding: 1em 0;
font-size: 1.2em;
color: #555;
}

.highcharts-data-table th {
font-weight: 600;
padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
background: #f8f8f8;
}

.highcharts-data-table tr:hover {
background: #f1f7ff;
}
.v-item-group.v-bottom-navigation .v-btn {
min-width: 0px !important;
}
.v-item-group.v-bottom-navigation--horizontal
.v-btn
> .v-btn__content
> .v-icon {
margin-right: 0px !important;
}

.centrale-box{
  padding-bottom: 55px;
}

.puissance-box {
padding: 0px;
margin-top: -35px;
}
.puissance-box .puissance-chart {
margin-bottom: 25px !important;
}
.puissance-box .v-row .v-col {
padding: 2px !important;
}

.niveaux-box {
margin-top: -35px;
}
.alarme-box {
text-align: center;
padding-top: 0px !important;
}
.comTrue {
background-color: #00b62d;
}

.comFalse {
background-color: #c90300;
animation-name: COM0;
animation-duration: 2s;
animation-iteration-count: infinite;
}

.v-btn--stacked.v-btn--size-default {
    min-width: 30px;
    padding: 0 0px;
}
@keyframes COM0 {
0% {
  background-color: #c90300;
}
25% {
  background-color: #c90300d3;
}
50% {
  background-color: #c90300b7;
}
75% {
  background-color: #c90300b9;
}
100% {
  background-color: #c90300e0;
}
}
</style>
<style>
/*import styles VUE MAP*/
@import 'leaflet/dist/leaflet.css';
@import 'vue-map-ui/dist/normalize.css';
@import 'vue-map-ui/dist/style.css';
@import 'vue-map-ui/dist/theme-all.css';
.productionInput input{
  padding: 0.5em !important;
  text-align: center !important;
  font-size : 0.9em;
}

.marker-round-icon {
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 23px;
  text-align: center;
}
</style>
