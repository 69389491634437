import { Home, Engine, Hydroweb, Users, Cameras } from '@/views/admin';

export default {
    path: '/admin',
    component: () => import('../layouts/default'),
    children: [
        { path: '', component: Home },
        { path: 'engine', component: Engine },
        { path: 'hydroweb', component: Hydroweb },
        { path: 'users', component: Users },
        { path: 'cameras', component: Cameras },
    ]
};