<template> 
  <v-dialog width="500" v-model="dialog">
    <template v-slot:default="{ isActive }">
      <v-card :title="textToDelete" v-if="dialogType=='delete'">
        <v-card-text v-if="alertDelete.length > 0">
          {{ alertDelete }}
        </v-card-text>
        <v-card-text v-else>
          Êtes-vous sur de vouloir supprimer cet élément ?
        </v-card-text>

        <v-card-actions v-if="alertDelete.length > 0">
          <v-spacer></v-spacer>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="alertDelete='';dialog = false;"
          >
            Fermer
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Non
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="deleteForUser()"
          >
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card :title="textToAdd" v-if="dialogType=='add'">
        <v-card-text v-if="alertAdd.length > 0">
          {{ alertAdd }}
        </v-card-text>
        <v-card-text v-else>
          <v-row v-for="elt in itemToAdd">
            <v-col cols="12">
              <v-text-field
                v-model="elt.value"
                :label="elt.title"
                variant="underlined"
                v-if="elt.type=='text'"
              ></v-text-field>
              <v-select
                v-model="elt.value"
                :clearable="elt.multiple"
                :chips="elt.multiple"
                :label="elt.title"
                :items="$data[elt.data]"
                :multiple="elt.multiple"
                variant="underlined"               
                item-title="name"
                item-value="id"
                v-if="elt.type=='select'"
              >
                <template v-slot:item="{ props, item }" v-if="elt.subtitle">
                  <v-list-item v-bind="props" :subtitle="item.raw.subtitle"></v-list-item>
                </template>
              </v-select>
              <v-radio-group
                v-model="elt.value"
                inline
                v-if="elt.type=='radio'"
              >
                <template v-slot:label>
                  <div>{{ elt.title }}</div>
                </template>
                <v-radio
                  label="Oui"
                  value="1"
                ></v-radio>
                <v-radio
                  label="Non"
                  value="0"
                  class="ml-5"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>       
        </v-card-text>

        <v-card-actions v-if="alertAdd.length > 0">
          <v-spacer></v-spacer>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="alertAdd='';dialog = false;"
          >
            Fermer
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="red-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="addForUser()"
            v-if="functionAdd == 'addForUser'"
          >
            Ajouter
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="addUser()"
            v-else="functionAdd == 'addUser'"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  <v-container fluid class="page-content">
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-account-group"></v-icon> &nbsp;
        Utilisateurs

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          label="Search"
          single-line
          flat
          hide-details
          variant="solo-filled"
        ></v-text-field>
        <v-btn
          prepend-icon="mdi-plus"
          @click="addDialog('Utilisateur',0)"
          class="ml-5"
        >
          <template v-slot:prepend>
            <v-icon color="success"></v-icon>
          </template>
          Ajouter
        </v-btn> 
        <v-btn
          prepend-icon="mdi-plus"
          @click="synchroHydro()"
          class="ml-5"
        >
          <template v-slot:prepend>
            <v-icon color="success"></v-icon>
          </template>
          Synchro Hydro
        </v-btn> 
      </v-card-title>

      <v-divider></v-divider>
      <v-data-table 
        v-model:expanded="expanded"
        v-model:search="search" 
        :items="users"
        :headers="(isAdmin)?headersAdmin:headers"
        item-key="id"
        items-per-page="30"        
        :loading="loadingData"
        loading-text="Chargement... Veuillez patienter svp"
        show-expand     
        @update:expanded="clickRow">               
        <template v-slot:item.firstname="{ columns, item }">
          <v-text-field
            v-model="item.firstname"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.firstname"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.lastname="{ columns, item }">
          <v-text-field
            v-model="item.lastname"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.lastname"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.email="{ columns, item }">
          <v-text-field
            v-model="item.email"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.email"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.login="{ columns, item }">
          <v-text-field
            v-model="item.login"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
          <v-text-field
            v-model="item.login"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:item.password="{ columns, item }">
          <v-text-field
            v-model="item.password"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
        </template>
        <template v-slot:item.pass_hydroweb="{ columns, item }">
          <v-text-field
            v-model="item.pass_hydroweb"
            variant="underlined"
            @input="item.updated = true"
            v-if="isAdmin"
          ></v-text-field>
        </template>
        <template v-slot:item.role="{ columns, item }">
          <v-select
            v-model="item.rights"
            :items="rightsList"
            variant="underlined"                 
            item-title="name"
            item-value="id"
            @update:modelValue="item.updated = true"
            v-if="isAdmin"
          ></v-select>
          <v-text-field
            v-model="item.role"
            variant="plain"
            readonly
            v-else
          ></v-text-field>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
          <tr>
            <td :colspan="columns.length">
              <v-row class="py-3">
                <v-col cols="6">
                  <v-card>
                    <v-card-title class="d-flex align-center pe-2">
                      <v-icon icon="mdi-database-cog"></v-icon> &nbsp;
                      Applications
                    </v-card-title>
                    <v-data-table 
                      :items="item.apps"
                      :headers="headersApp"
                      item-key="id"
                      items-per-page="30">    
                      <template v-slot:header.actions>
                        <div class="text-end">
                          <v-btn
                            prepend-icon="mdi-plus"
                            @click="addDialog('Applications',item.id)"
                          >
                            <template v-slot:prepend>
                              <v-icon color="success"></v-icon>
                            </template>

                            Ajouter
                          </v-btn>                        
                        </div>
                      </template>              
                      <template v-slot:item.actions="{ item }">
                        <div style="width:100%;position:absolute;"></div>
                        <v-icon
                          size="small"
                          @click="deleteDialog('Applications',item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template> 
                      <template v-slot:item.role="{ columns, item }">
                        <v-select
                          v-model="item.id_rights_hydro"
                          :items="rightsHydroList"
                          variant="underlined"                 
                          item-title="name"
                          item-value="id"
                          @update:modelValue="updateAppsForUser(item)"
                          :hint="item.hint"
                          :persistent-hint="item.persistentHint"
                          :class="item.class"
                          v-if="isAdmin"
                        ></v-select>
                        <v-text-field
                          v-model="item.role"
                          variant="plain"
                          readonly
                          v-else
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title class="d-flex align-center pe-2">
                          <v-icon icon="mdi-account-group"></v-icon> &nbsp;
                          Groupes
                        </v-card-title>
                        <v-data-table 
                          :items="item.groups"
                          :headers="headersGroups"
                          item-key="id"
                          items-per-page="30">  
                          <template v-slot:header.actions>
                            <div class="text-end">
                              <v-btn
                                prepend-icon="mdi-plus"
                                @click="addDialog('Groupes',item.id)"
                              >
                                <template v-slot:prepend>
                                  <v-icon color="success"></v-icon>
                                </template>

                                Ajouter
                              </v-btn>                        
                            </div>
                          </template>    
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              size="small"
                              @click="deleteDialog('Groupes',item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title class="d-flex align-center pe-2">
                          <v-icon icon="mdi-page-layout-body"></v-icon> &nbsp;
                          Pages
                        </v-card-title>
                        <v-data-table 
                          :items="item.pages"
                          :headers="headersPages"
                          item-key="id"
                          items-per-page="30">   
                          <template v-slot:header.actions>
                            <div class="text-end">
                              <v-btn
                                prepend-icon="mdi-plus"
                                @click="addDialog('Pages',item.id)"
                              >
                                <template v-slot:prepend>
                                  <v-icon color="success"></v-icon>
                                </template>

                                Ajouter
                              </v-btn>                        
                            </div>
                          </template>  
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              size="small"
                              @click="deleteDialog('Pages',item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template> 
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col> 
              </v-row>            
            </td>
          </tr>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            size="large"
            class="me-2"
            @click="saveItem(item)"
            v-if="item.updated"
          >
            mdi-check-bold
          </v-icon>
          <v-icon
            size="medium"
            @click="cancelItem(item)"
            v-if="item.updated"
          >
            mdi-cancel
          </v-icon>
        </template>       
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.successHint >>> .v-messages{
  text-align: left !important;
  color: #1c9500 !important;
  top : -3px;
}
.errorHint >>> .v-messages{
  text-align: left !important;
  color: #f00 !important;
  top : -3px;
}
</style>
<script>
import axios from "axios";
import moment from 'moment';
import { useNavbarStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useAuthStore } from '@/stores';


export default {
  props : {
  },
  data () {
    return {
      isAdmin : false,
      title : 'Administration',
      tabs: [],
      tab : null,
      headersAdmin : [ 
        { title: 'Id', value: 'id', align : 'center' },
        { title: 'Nom', value: 'lastname', align : 'center' },
        { title: 'Prénom', value: 'firstname', align : 'center' },
        { title: 'Email', value: 'email', align : 'center' },
        { title: 'Identifiant', value: 'login' , align : 'center'},
        { title: 'Mot de Passe', value: 'password', align : 'center'},
        { title: 'Pass Hydroweb', value: 'pass_hydroweb', align : 'center'},
        { title: 'Rôle', value: 'role', align : 'center'},
        /*{ title: 'Ajouté le', value: 'date_added' },
        { title: 'Dernière modification', value: 'last_modified' },*/
        { title: 'Action', key: 'actions', align : 'end' },      
        { title: '', key: 'data-table-expand', align : 'center' },],   
      headers : [ 
        { title: 'Id', value: 'id', align : 'center' },
        { title: 'Nom', value: 'lastname', align : 'center' },
        { title: 'Prénom', value: 'firstname', align : 'center' },
        { title: 'Email', value: 'email', align : 'center' },
        { title: 'Identifiant', value: 'login' , align : 'center'},
        { title: 'Rôle', value: 'role', align : 'center'},
        /*{ title: 'Ajouté le', value: 'date_added' },
        { title: 'Dernière modification', value: 'last_modified' },*/
        { title: 'Action', key: 'actions', align : 'end' },      
        { title: '', key: 'data-table-expand', align : 'center' },],      
      users : [],
      search: '',
      expanded: [],
      rightsList : [],
      rightsHydroList : [],
      appsList : [],
      pagesList : [],
      groupsList : [],
      formatList : [],
      loadingData : true,
      headersGroups : [
        { title: 'Id', value: 'id', align : 'center' },
        { title: 'Nom', value: 'name', align : 'center'},
        { title: 'Action', key: 'actions', align : 'end' },   
      ],
      headersPages : [
        { title: 'Id', value: 'id', align : 'center' },
        { title: 'Module', value: 'module', align : 'center'},
        { title: 'Nom', value: 'name', align : 'center'},
        { title: 'Par défaut', value: 'default', align : 'center'},
        { title: 'Active', value: 'active', align : 'center'},
        { title: 'Action', key: 'actions', align : 'end' },   
      ],
      headersApp : [
        { title: 'Id', value: 'id', align : 'center' },
        { title: 'Nom', value: 'name', align : 'center'},
        { title: 'Role', value: 'role', align : 'center'},
        { title: 'Action', key: 'actions', align : 'end' },   
      ],
      hasExpanded : [],
      dialog : false,
      textToDelete : '',
      itemToDelete : [],
      alertDelete : '',
      textToAdd : '',
      itemToAdd : [],
      alertAdd : '',
      dialogType : '',
      functionAdd : '',
      formUser : [
       {title : 'Id', value : '', visible : false, type : ''},
       {title : 'Nom', value : '', visible : true, type : 'text', subtitle : false},
       {title : 'Prénom', value : '', visible : true, type : 'text',subtitle : false},
       {title : 'Email', value : '', visible : true, type : 'text', subtitle : false},
       {title : 'Identifiant', value : '', visible : true, type : 'text',subtitle : false},
       {title : 'Mot de passe', value : '', visible : true, type : 'text', subtitle : false},
       {title : 'Pass Hydroweb', value : '', visible : true, type : 'text', subtitle : false},
       {title : 'Rôle', value : 0, visible : true, type : 'select', multiple : false, data : 'rightsList', subtitle : false}] ,
      formApp : [
       {title : 'Id', value : '', visible : false, type : ''},
       {title : 'App', value : [], visible : true, type : 'select', multiple : true, data : 'formatList', subtitle : false},
       {title : 'Rôle', value : 0, visible : true, type : 'select', multiple : false, data : 'rightsHydroList', subtitle : false}] ,
      formGroupe : [
       {title : 'Id', value : '', visible : false, type : ''},
       {title : 'Groupe', value : [], visible : true, type : 'select', multiple : true, data : 'formatList', subtitle : false}] ,
      formPage : [
       {title : 'Id', value : '', visible : false, type : ''},
       {title : 'Page', value : [], visible : true, type : 'select', multiple : true, data : 'formatList', subtitle : true},
       {title : 'Active', value : 0, visible : true, type : 'radio'}] 
    }
  },  
  watch : {
    dialog(){
      if(this.dialog == false){
        this.textToDelete = '';
        this.itemToDelete = [];
        this.alertDelete = '';
        this.textToAdd = '';
        this.itemToAdd = [];
        this.alertAdd = '';
        this.dialogType = '';
      }
    } 
  },
  mounted() {
    this.getRightsList();
    this.getRightsHydroList();
    this.getAppsList();
    this.getGroupeList();
    this.getPageList();
    this.getAdmin();
    this.init();
  },
  methods: {
    async getAdmin(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      var vm = this;     
      try {    
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/user/'+authStore.user.id+'/admin', config);
        if(response.data){
           vm.isAdmin = response.data;
        }
      } catch (error) {
        alertStore.error(error);
      }      
    },
    async getPageList(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      var vm = this;     
      try {    
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/user/pages', config);
        if(response.data){
           vm.pagesList = response.data;
        }
      } catch (error) {
        alertStore.error(error);
      }
    },
    async getGroupeList(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      var vm = this;     
      try {    
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/user/groupes', config);
        if(response.data){
           vm.groupsList = response.data;
        }
      } catch (error) {
        alertStore.error(error);
      }
    },
    async getRightsList(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      var vm = this;     
      try {    
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/user/rights', config);
        if(response.data){
           vm.rightsList = response.data;
        }
      } catch (error) {
        alertStore.error(error);
      }
    },
    async getRightsHydroList(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      var vm = this;     
      try {    
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/user/rightshydro', config);
        if(response.data){
           vm.rightsHydroList = response.data;
        }
      } catch (error) {
        alertStore.error(error);
      }
      /*try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getRightsHydroList');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/account.php', formData)
        .then(function (response) {    
            if(response.data){              
              vm.rightsHydroList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } */
    },
    async getAppsList(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      var vm = this;     
      try {    
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/apps/active', config);
        if(response.data){
           vm.appsList = response.data;
        }
      } catch (error) {
        alertStore.error(error);
      }
      /*try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getActiveHydrowebs');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {    
          console.log(response)
            if(response.data){              
              vm.hydrowebList = response.data;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } */
    },    
    async init(){
      const navbarStore = useNavbarStore();
      const authStore = useAuthStore();
      const alertStore = useAlertStore();     
      this.isAdmin = authStore.isAdmin();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(null);
      navbarStore.setRight(null);
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
      try {        
        var vm = this;
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        const response = await axios.get(this.apiJsUrl+'/user/userswithrights', config);
        if(response.data){  
              console.log(response.data);
              for (let i = 0; i < response.data.length; i++) {
                vm.users.push({
                  id : response.data[i].id, 
                  firstname : response.data[i].firstname,
                  lastname : response.data[i].lastname,
                  email : response.data[i].email,
                  login : response.data[i].login,
                  password : '',
                  pass_hydroweb : response.data[i].pass_hydroweb,
                  rights : response.data[i].rights,
                  role : response.data[i].role,
                  date_added : response.data[i].date_added,
                  last_modified : response.data[i].last_modified,
                  updated : false,
                  show_password : false,
                  loading : true,
                  groups : [],
                  apps : [],
                  pages : [],
                  original : {
                    firstname : response.data[i].firstname,
                    lastname : response.data[i].lastname,
                    email : response.data[i].email,
                    login : response.data[i].login,
                    rights : response.data[i].rights,
                  }
                });
              }
              vm.loadingData = false;
            }                       
      } catch (error) {   
        alertStore.error(error);
      }               
    },
    async clickRow(item) {  
      if(item.length > 0){
        const authStore = useAuthStore();
        const alertStore = useAlertStore();
        const id = item[item.length-1];
        var idList = this.users.map(el => el.id);
        var index = idList.indexOf(id);
        const userName = this.users[index].lastname+' '+this.users[index].firstname;
        if(!this.hasExpanded.includes(id)){
          try {        
            var vm = this;
            const config = {
              headers: { Authorization: `Bearer ${authStore.user.token.token}` }
            };
            const response = await axios.get(this.apiJsUrl+'/user/'+id+'/data', config);
            if(response.data){ 
                  vm.hasExpanded.push(id);
                  for (let i = 0; i < response.data.groups.length; i++) {
                    vm.users[index].groups.push({
                      id : response.data.groups[i].id_group,
                      name : response.data.groups[i].name,
                      user : id,
                      userName : userName
                    });
                  }
                  for (let i = 0; i < response.data.apps.length; i++) {
                    vm.users[index].apps.push({
                      id : response.data.apps[i].id_app,
                      name : response.data.apps[i].name,
                      id_rights_hydro : response.data.apps[i].id_right_app,
                      role : response.data.apps[i].role, 
                      user : id,    
                      userName : userName,    
                      hint : '',
                      persistentHint : false, 
                      class : ''
                    });
                  }
                  for (let i = 0; i < response.data.pages.length; i++) {
                    vm.users[index].pages.push({
                      id : response.data.pages[i].id_page,
                      module : response.data.pages[i].module,
                      name : response.data.pages[i].name,
                      default : response.data.pages[i].default,
                      active : response.data.pages[i].active, 
                      user : id,  
                      userName : userName,       
                      hint : '',
                      persistentHint : false,
                      class : ''
                    });
                  }
                  vm.users[index].loading = false;
                }          
          } catch (error) {   
            alertStore.error(error);
          } 
        }
      }
    },    
    async addUser(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      try {        
        var vm = this;
        var formData = new FormData();
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        formData.append('firstname',  vm.formUser[2].value);
        formData.append('lastname',  vm.formUser[1].value);
        formData.append('email',  vm.formUser[3].value);
        formData.append('login',  vm.formUser[4].value);
        formData.append('password',  vm.formUser[5].value);
        formData.append('pass_hydroweb',  vm.formUser[5].value);
        formData.append('rights',  vm.formUser[6].value);
        axios
        .post(this.apiJsUrl+'/user/register', formData, config)
        .then(function (response) {    
            if(response.data){       
              vm.dialog = false;      
              if(response.data.statut == false){
                var alert = '';
                for (let index = 0; index < response.data.messages.length; index++) {
                    alert += response.data.messages[index]+'<br>';                
                }                      
                alertStore.error(alert);       
              }else{
                var idRightsList = vm.rightsList.map(el => el.id);
                var index = idRightsList.indexOf(vm.formUser[6].value);
                vm.users.push({
                    id : response.data.id, 
                    firstname : vm.formUser[2].value,
                    lastname : vm.formUser[1].value,
                    email : vm.formUser[3].value,
                    login : vm.formUser[4].value,
                    password : '',
                    pass_hydroweb : vm.formUser[5].value,
                    rights : vm.formUser[6].value,
                    role : vm.rightsList[index].name,
                    date_added : moment().format('YYYY-MM-DD HH:mm:ss'),
                    last_modified : moment().format('YYYY-MM-DD HH:mm:ss'),
                    updated : false,
                    show_password : false,
                    loading : true,
                    groups : [],
                    apps : [],
                    pages : [],
                    original : {
                      firstname : vm.formUser[2].value,
                      lastname : vm.formUser[1].value,
                      email : vm.formUser[3].value,
                      login : vm.formUser[4].value,
                      rights : vm.formUser[6].value,
                    }
                  });
                alertStore.success('Utilisateur ajouté');
              } 
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }      
    },
    async saveItem(item){
      console.log(item)
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      var index = this.users.indexOf(item);
      try {        
        var vm = this;
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        var formData = new FormData();
        formData.append('firstname', item.firstname);
        formData.append('lastname', item.lastname);
        formData.append('email', item.email);
        formData.append('login', item.login);
        formData.append('password', item.password);
        formData.append('pass_hydroweb', item.pass_hydroweb);
        formData.append('rights', item.rights);
        axios
        .put(this.apiJsUrl+'/user/'+item.id, formData, config)
        .then(function (response) {    
            if(response.data){
              vm.users[index].original.firstname = item.firstname;
              vm.users[index].original.lastname = item.lastname;
              vm.users[index].original.email = item.email;
              vm.users[index].original.login = item.login;
              vm.users[index].original.rights = item.rights;
              vm.users[index].original.pass_hydroweb = item.pass_hydroweb;
              vm.users[index].last_modified = moment().format('YYYY-MM-DD HH:mm:ss');
              vm.users[index].updated = false;
              alertStore.success('Utilisateur mis à jour');
            }          
        }).catch(function (error) {   
        console.log(error)  
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) { 
        console.log(error)  
        alertStore.error(error);
      }      
    },
    cancelItem(item){
      var index = this.users.indexOf(item);
      this.users[index].firstname = this.users[index].original.firstname;
      this.users[index].lastname = this.users[index].original.lastname;
      this.users[index].email = this.users[index].original.email;
      this.users[index].login = this.users[index].original.login;
      this.users[index].rights = this.users[index].original.rights;
      this.users[index].updated = false;
    },
    addDialog(name,id){
      var idList = this.users.map(el => el.id);
      var indexUser = idList.indexOf(id);
      this.dialogType = 'add';
      this.dialog = true;
      this.typeToAdd = name;
      this.formatList = [];          
      if(name == 'Utilisateur'){
        this.functionAdd = 'addUser';
        this.itemToAdd = this.formUser;
        this.textToAdd = 'Nouvel utilisateur'; 
      }else{
        this.functionAdd = 'addForUser';
        this.textToAdd = name+' pour l\'utilisateur '+this.users[indexUser].lastname+' '+this.users[indexUser].firstname; 
        if(name == 'Applications'){
          this.itemToAdd = this.formApp;      
          var idListApps = this.users[indexUser].apps.map(el => el.id);
          this.appsList.forEach(element => {
            if(!idListApps.includes(element.id)){
                this.formatList.push(element)
            }       
          });
        }else if(name == 'Groupes'){
          this.itemToAdd = this.formGroupe;
          var idListGroupe = this.users[indexUser].groups.map(el => el.id);
          this.groupsList.forEach(element => {
            if(!idListGroupe.includes(element.id)){
                this.formatList.push(element)
            }       
          });
        }else if(name == 'Pages'){
          this.itemToAdd = this.formPage;
          var idListPage = this.users[indexUser].pages.map(el => el.id);
          this.pagesList.forEach(element => {
            if(!idListPage.includes(element.id)){
                this.formatList.push(element)
            }       
          });
        }
        this.itemToAdd[0].value = this.users[indexUser].id;
      }
    },
    deleteDialog(name,item){
      this.dialogType = 'delete';
      this.textToDelete = name+' '+item.name+' pour l\'utilisateur '+item.userName; 
      this.dialog = true;
      this.typeToDelete = name;
      this.itemToDelete = item;
    },
    async deleteForUser(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      var idList = this.users.map(el => el.id);
      var indexUser = idList.indexOf(this.itemToDelete.user);
      var index = 0;
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'deleteForUser');
        formData.append('type', this.typeToDelete);
        formData.append('data', JSON.stringify(this.itemToDelete));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/account.php', formData)
        .then(function (response) {    
            if(response.data.statut){
              if(vm.typeToDelete == 'Applications'){
                index = vm.users[indexUser].apps.indexOf(vm.itemToDelete);
                vm.users[indexUser].apps.splice(index, 1);
              }else if(vm.typeToDelete == 'Groupes'){ 
                index = vm.users[indexUser].groups.indexOf(vm.itemToDelete);
                vm.users[indexUser].groups.splice(index, 1);
              }else if(vm.typeToDelete == 'Pages'){
                index = vm.users[indexUser].pages.indexOf(vm.itemToDelete);
                vm.users[indexUser].pages.splice(index, 1);
              }
              vm.alertDelete = "Suppression réussie";
            }else{             
              vm.alertDelete = "Supression échouée";
            }          
            /*vm.$nextTick(() => { 
              setTimeout(function(){vm.dialog = false;vm.alertDelete = '';}, 2500);    
            });*/
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    },
    async addForUser(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      var idList = this.users.map(el => el.id);
      var indexUser = idList.indexOf(this.itemToAdd[0].value);
      try {        
        var vm = this;
        const config = {
          headers: { Authorization: `Bearer ${authStore.user.token.token}` }
        };
        var formData = new FormData();
        var url = this.apiJsUrl+'/user/'+this.itemToAdd[0].value
        formData.append('id_user', this.itemToAdd[0].value);
        if(vm.typeToAdd == 'Applications'){
          url += '/apps'      
          formData.append('data', JSON.stringify(this.itemToAdd[1].value));
          formData.append('id_right_app', this.itemToAdd[2].value);
        }else if(vm.typeToAdd == 'Groupes'){   
          url += '/groups' 
          formData.append('data', JSON.stringify(this.itemToAdd[1].value));
        }else if(vm.typeToAdd == 'Pages'){          
          url += '/pages' 
          formData.append('data', JSON.stringify(this.itemToAdd[1].value));
          formData.append('active', this.itemToAdd[2].value);
        }
        axios
        .post(url, formData, config)
        .then(function (response) {    
            console.log(response)
            if(response.data.values.length > 0){
              const userName = vm.users[indexUser].lastname+' '+vm.users[indexUser].firstname;
              vm.alertAdd = "Insertion réussie";
              if(vm.typeToAdd == 'Applications'){
                var idListApp = vm.appsList.map(el => el.id);
                var idListRightsHydro = vm.rightsHydroList.map(el => el.id);
                var indexRightsHydro = idListRightsHydro.indexOf(vm.itemToAdd[2].value);
                for (let index = 0; index < response.data.values.length; index++) {
                  let indexApp = idListApp.indexOf(response.data.values[index]);
                  vm.users[indexUser].apps.push({
                        id : response.data.values[index],
                        name : vm.appsList[indexApp].name,
                        id_rights_hydro : vm.itemToAdd[2].value,
                        role : vm.rightsHydroList[indexRightsHydro].name,
                        user : vm.itemToAdd[0].value,    
                        userName : userName,    
                        hint : '',
                        persistentHint : false, 
                        class : ''
                      });
                }
              }else if(vm.typeToAdd == 'Groupes'){
                var idListGroups = vm.groupsList.map(el => el.id);
                for (let index = 0; index < response.data.values.length; index++) {
                  let indexGroups = idListGroups.indexOf(response.data.values[index]);
                  vm.users[indexUser].groups.push({
                      id : response.data.values[index],
                      name : vm.groupsList[indexGroups].name,
                      user : vm.itemToAdd[0].value,
                      userName : userName
                    });               
                }

              }else if(vm.typeToAdd == 'Pages'){
                var idListPages = vm.pagesList.map(el => el.id);
                for (let index = 0; index < response.data.values.length; index++) {
                var indexPages = idListPages.indexOf(response.data.values[index]);
                  vm.users[indexUser].pages.push({
                        id : response.data.values[index],
                        module : vm.pagesList[indexPages].subtitle,
                        name : vm.pagesList[indexPages].name,
                        default : 0,
                        active : vm.itemToAdd[2].value, 
                        user : vm.itemToAdd[0].value,  
                        userName : userName,       
                        hint : '',
                        persistentHint : false,
                        class : ''
                      });
                }
              }            
            }
            if(response.data.statut == false){
              for (let index = 0; index < response.data.messages.length; index++) {
                  vm.alertAdd += response.data.messages[index]+'<br>';                
              }             
            }          
            /*vm.$nextTick(() => { 
              setTimeout(function(){vm.dialog = false;vm.alertAdd = '';}, 2500);    
            });*/
        }).catch(function (error) {   
          console.log(error)
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
          console.log(error)
        alertStore.error(error);
      } 
    },
    async updateAppsForUser(item){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      var idList = this.users.map(el => el.id);
      var indexUser = idList.indexOf(item.user);
      var index = this.users[indexUser].apps.indexOf(item);
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'setHydrowebForUser');
        formData.append('data', JSON.stringify(item));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/account.php', formData)
        .then(function (response) {    
            if(response.data){
              vm.users[indexUser].apps[index].hint = "Mise à jour réussie";
              vm.users[indexUser].apps[index].persistentHint = true;
              vm.users[indexUser].apps[index].class = 'successHint';
            }else{             
              vm.users[indexUser].apps[index].hint = "Mise à jour échouée";
              vm.users[indexUser].apps[index].persistentHint = true;
              vm.users[indexUser].apps[index].class = 'errorHint';
            }          
            vm.$nextTick(() => { 
              setTimeout(function(){vm.users[indexUser].apps[index].persistentHint = false;vm.users[indexUser].apps[index].hint = '';}, 2500);    
            });
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }  
    },
    synchroHydro(){

    }
  },
};
</script>