import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert',{
    id: 'alert',
    state: () => ({
        alert: null
    }),
    actions: {
        success(message) {
            this.alert = { message : message, type: 'success', title: 'Félicitation' };
        },
        error(message) {
            this.alert = { message : message, type: 'error', title: 'Erreur' };
        },
        warning(message) {
            this.alert = { message : message, type: 'warning', title: 'Attention' };
        },
        info(message) {
            this.alert = { message : message, type: 'info', title: 'Informations' };
        },
        clear() {
            this.alert = null;
        }
    }
});