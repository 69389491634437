<template> 
  <v-container fluid class="page-content">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel
        v-for="hydroweb in hydrowebs"
        :key="hydroweb.id"
        :value="hydroweb.id"
      >
        <v-expansion-panel-title :class="hydroweb.class" @click="getHydrowebInfo(hydroweb.id)">
          <template v-slot:default="{ expanded }" >
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-start">
                {{ hydroweb.name }}
              </v-col>             
            </v-row>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row v-if="PanelReady == false">
            <v-col cols="12">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="3">
              <v-card
                class="mx-auto "
              >

                <v-card-title class="bg-green-lighten-4">Hydroweb</v-card-title>

                <v-card-text class="my-4">
                  <v-text-field
                    v-model="hydroweb.content.host"
                    color="primary"
                    label="Hôte"
                    variant="underlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="hydroweb.content.port_api"
                    color="primary"
                    label="Url Api"
                    variant="underlined"
                  ></v-text-field>
                  <v-select
                    v-model="hydroweb.content.version"
                    :items="versionsList"
                    item-title="name"
                    item-value="id"
                    label="Version Hydroweb"
                    variant="underlined"
                  ></v-select>
                  <v-switch
                    color="green-lighten-2"
                    v-model="hydroweb.content.active"
                    value="1"
                    :true-value="1"
                    :false-value="0"
                    label="Actif"
                  ></v-switch>
                </v-card-text>

                <v-card-actions>
                    <v-chip
                      class="ma-2 pa-2"
                      color="green"
                      text-color="white"
                      prepend-icon="mdi-checkbox-marked-circle"
                      v-if="hydroweb.hydroweb == 'ok'"
                    >
                      Ok
                    </v-chip>
                    <v-chip
                      class="ma-2 pa-2"
                      color="red"
                      text-color="white"
                      prepend-icon="mdi-alert-circle"
                      v-else-if="hydroweb.hydroweb == 'error'"
                    >
                      Erreur
                    </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn color="green-lighten-2" @click="saveHydrowebData(hydroweb.id)">
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="mx-auto "
              >

                <v-card-title class="bg-blue-lighten-4">Hyperviseur</v-card-title>

                <v-card-text class="my-4">
                  <template v-for="hyperviseurType in hyperviseurTypeList" :key="hyperviseurType.id">
                    <v-select                           
                      v-model="hydroweb.content.hyperviseur[hyperviseurType.id]"
                      :items="hydroweb.content.ressourcePuissance"
                      item-title="concatName"
                      item-value="id"
                      :label="hyperviseurType.name"
                      variant="underlined"
                      chips 
                      :multiple="(hyperviseurType.multiple == 0)?false:true" 
                      v-if="hyperviseurType.group == 'puissance'"                  
                    ></v-select>
                    <v-select                           
                      v-model="hydroweb.content.hyperviseur[hyperviseurType.id]"
                      :items="hydroweb.content.ressourceProduction"
                      item-title="concatName"
                      item-value="id"
                      :label="hyperviseurType.name"
                      variant="underlined"
                      chips 
                      :multiple="(hyperviseurType.multiple == 0)?false:true" 
                      v-if="hyperviseurType.group == 'production'"                  
                    ></v-select>
                    <v-select                           
                      v-model="hydroweb.content.hyperviseur[hyperviseurType.id]"
                      :items="hydroweb.content.ressourceNiveau"
                      item-title="concatName"
                      item-value="id"
                      :label="hyperviseurType.name"
                      variant="underlined"
                      chips 
                      :multiple="(hyperviseurType.multiple == 0)?false:true" 
                      v-if="hyperviseurType.group == 'niveau'"                  
                    ></v-select>
                  </template>                 
                </v-card-text>

                <v-card-actions>
                    <v-chip
                      class="ma-2 pa-2"
                      color="green"
                      text-color="white"
                      prepend-icon="mdi-checkbox-marked-circle"
                      v-if="hydroweb.hyperviseur == 'ok'"
                    >
                      Ok
                    </v-chip>
                    <v-chip
                      class="ma-2 pa-2"
                      color="red"
                      text-color="white"
                      prepend-icon="mdi-alert-circle"
                      v-else-if="hydroweb.hyperviseur == 'error'"
                    >
                      Erreur
                    </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn color="green-lighten-2" @click="saveHyperviseurData(hydroweb.id)">
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                class="mx-auto "
              >

                <v-card-title class="bg-orange-lighten-4">Utilisateurs</v-card-title>

                <v-card-text class="my-4 userPanel">
                  <v-data-table
                    :headers="headerUser"
                    class="elevation-1"
                    :items-per-page="20"
                    :items="hydroweb.content.users"
                  >
                  </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-chip
                      class="ma-2 pa-2"
                      color="green"
                      text-color="white"
                      prepend-icon="mdi-checkbox-marked-circle"
                      v-if="hydroweb.hydroweb == 'ok'"
                    >
                      Ok
                    </v-chip>
                    <v-chip
                      class="ma-2 pa-2"
                      color="red"
                      text-color="white"
                      prepend-icon="mdi-alert-circle"
                      v-else-if="hydroweb.hydroweb == 'error'"
                    >
                      Erreur
                    </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn color="green-lighten-2" @click="synchroUsers(hydroweb.content.hostapi,hydroweb.content.version,hydroweb.content.users)">
                    Synchroniser
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card
                class="mx-auto "
              >

                <v-card-title class="bg-yellow-lighten-4">Groupes</v-card-title>

                <v-card-text class="my-4">
                  <v-select
                    v-model="hydroweb.content.groups"
                    :items="groupsList"
                    item-title="name"
                    item-value="id"
                    label="Groupe"
                    variant="underlined"                   
                    chips
                    multiple
                  ></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-chip
                      class="ma-2 pa-2"
                      color="green"
                      text-color="white"
                      prepend-icon="mdi-checkbox-marked-circle"
                      v-if="hydroweb.groups == 'ok'"
                    >
                      Ok
                    </v-chip>
                    <v-chip
                      class="ma-2 pa-2"
                      color="red"
                      text-color="white"
                      prepend-icon="mdi-alert-circle"
                      v-else-if="hydroweb.groups == 'error'"
                    >
                      Erreur
                    </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn color="green-lighten-2" @click="saveGroupsData(hydroweb.id)">
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" >
              <v-btn class="bg-blue-grey-lighten-3" block>
                Tableau des ressources
              </v-btn>
            </v-col>
          </v-row>         
        </v-expansion-panel-text>    
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import axios from "axios";
import { useNavbarStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useAuthStore } from '@/stores';
export default {
  props : {
  },
  data () {
    return {
      title : 'Administration',
      tabs: [],
      tab : null,
      hydrowebs : [],
      panel : null,
      usersList : [],
      groupsList : [],
      rightsList : [],
      versionsList : [],
      hyperviseurTypeList : [],
      PanelReady : false,
      headerUser : [ 
        {title: 'Utilisateur',key: 'name',sortable : false,align :'center'},
        {title: 'Login',key: 'login',sortable : false,align :'center'},
        {title: 'Password',key: 'password',sortable : false,align :'center'},
        {title: 'Role',key: 'role',sortable : false,align :'center'},
      ],
    }
  },  
computed: {
  /*ready:  () => {
    this.init();
  },*/
},
mounted() {
  this.init();
  this.createPanel();
},
methods: {
  init(){
      const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      const authStore = useAuthStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(null);
      navbarStore.setRight(null);
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'init');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {    
            if(response.data){
              for (let i = 0; i < response.data.usersList.length; i++) {
                vm.usersList.push({
                  id : response.data.usersList[i].id, 
                  name : response.data.usersList[i].name
                });
                let usersIndexList = vm.usersList.map(el => el.id);
                let usersIndex = usersIndexList.indexOf(response.data.usersList[i].id);
                for (let k = 0; k < vm.rightsList.length; k++) {
                  vm.usersList[usersIndex][vm.rightsList[k].id] = 0;
                }  
              }
              console.log(vm.usersList);
              vm.groupsList = response.data.groupsList;
              vm.rightsList = response.data.rightsList;        
             /* for (let j = 0; j < vm.rightsList.length; j++) {
                vm.headerUser.push({
                  title: vm.rightsList[j].name,
                  key: vm.rightsList[j].id,
                  sortable : false,
                  align :'center'
                });
              }*/
              
              vm.versionsList = response.data.versionsList;
              vm.hyperviseurTypeList = response.data.hyperviseurTypeList;
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
  },
  createPanel(){ 
      const alertStore = useAlertStore();    
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydrowebs');
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {    
            if(response.data){
                for (let index = 0; index < response.data.length; index++) {
                  if(response.data[index].active == 1){
                    vm.hydrowebs.push({
                              id : response.data[index].id,
                              name : response.data[index].name,
                              class : 'bg-green-lighten-4',
                              content : []
                            });  
                  }else{
                    vm.hydrowebs.push({
                              id : response.data[index].id,
                              name : response.data[index].name,
                              class : 'bg-red-lighten-4',
                              content : []
                            });  
                  }
                                     
                }
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }          
  },
  getHydrowebInfo(id){
    this.PanelReady = false;
    const alertStore = useAlertStore();    
    var vm = this;
    let hydrowebsIndexList = vm.hydrowebs.map(el => el.id);
    let hydrowebIndex = hydrowebsIndexList.indexOf(id);
    if(vm.hydrowebs[hydrowebIndex].content.length <= 0){
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydroweb');
        formData.append('id', id);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) { 
            if(response.data){
              vm.PanelReady = true;
              console.log(response.data)
              vm.hydrowebs[hydrowebIndex].content = response.data;  
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }   
    }else{
      vm.PanelReady = true;
    }      
  },
  saveHydrowebData(id){
    const alertStore = useAlertStore();    
    var vm = this;
    let hydrowebsIndexList = vm.hydrowebs.map(el => el.id);
    let hydrowebIndex = hydrowebsIndexList.indexOf(id);
    try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'setHydrowebData');
        formData.append('id', id);
        formData.append('host', vm.hydrowebs[hydrowebIndex].content.host);
        formData.append('port_api', vm.hydrowebs[hydrowebIndex].content.port_api);
        formData.append('version', vm.hydrowebs[hydrowebIndex].content.version);
        formData.append('active', vm.hydrowebs[hydrowebIndex].content.active);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {  
            if(response.data){
              vm.hydrowebs[hydrowebIndex].content.message = response.data.message;  
              if(!response.data.statut){
                vm.hydrowebs[hydrowebIndex].hydroweb = 'error';
                console.log(response.data.message);
              }else{
                vm.hydrowebs[hydrowebIndex].hydroweb = 'ok';
                if(vm.hydrowebs[hydrowebIndex].content.active == '1'){
                  vm.hydrowebs[hydrowebIndex].class = 'bg-green-lighten-4';
                }else{
                  vm.hydrowebs[hydrowebIndex].class = 'bg-red-lighten-4';
                }
              }
              vm.$nextTick(() => {         
                  setTimeout(function(){vm.hydrowebs[hydrowebIndex].hydroweb = ''}, 5000);                
              });
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }     
  },
  saveHyperviseurData(id){
    const alertStore = useAlertStore();    
    var vm = this;
    let hydrowebsIndexList = vm.hydrowebs.map(el => el.id);
    let hydrowebIndex = hydrowebsIndexList.indexOf(id);
    try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'setHyperviseurData');
        formData.append('id', id);
        formData.append('data', JSON.stringify(vm.hydrowebs[hydrowebIndex].content.hyperviseur));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {  
            if(response.data){
              vm.hydrowebs[hydrowebIndex].content.message = response.data.message;  
              if(!response.data.statut){
                vm.hydrowebs[hydrowebIndex].hyperviseur = 'error';
                console.log(response.data.message);
              }else{
                vm.hydrowebs[hydrowebIndex].hyperviseur = 'ok';
              }
              vm.$nextTick(() => {         
                  setTimeout(function(){vm.hydrowebs[hydrowebIndex].hyperviseur = ''}, 5000);                
              });
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }     
  },
  saveGroupsData(id){
    const alertStore = useAlertStore();    
    var vm = this;
    let hydrowebsIndexList = vm.hydrowebs.map(el => el.id);
    let hydrowebIndex = hydrowebsIndexList.indexOf(id);
    try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'setGroupsData');
        formData.append('id', id);
        formData.append('data', JSON.stringify(vm.hydrowebs[hydrowebIndex].content.groups));
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydroweb.php', formData)
        .then(function (response) {  
            if(response.data){
              vm.hydrowebs[hydrowebIndex].content.message = response.data.message;  
              if(!response.data.statut){
                vm.hydrowebs[hydrowebIndex].groups = 'error';
                console.log(response.data.message);
              }else{
                vm.hydrowebs[hydrowebIndex].groups = 'ok';
              }
              vm.$nextTick(() => {         
                  setTimeout(function(){vm.hydrowebs[hydrowebIndex].groups = ''}, 5000);                
              });
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      }     
  },  
  synchroUsers(host,version,users){
    const alertStore = useAlertStore();    
    var vm = this;
    try {  
    var formDataEngineCheck = new FormData();
                formDataEngineCheck.append('action', 'checkOnline');
                formDataEngineCheck.append('url', host);
                axios
                .post(vm.apiUrl+'/hyperviseur_engine.php', formDataEngineCheck)
                .then(function (responseEngineCheck) {
                  if(responseEngineCheck.data){ 
                    if(responseEngineCheck.data.comFileTxt == 200){
                      var formDataEngine = new FormData();
                      formDataEngine.append('action', 'synchroUser');
                      formDataEngine.append('url', host);
                      formDataEngine.append('v', version);
                      formDataEngine.append('users', JSON.stringify(users));
                      axios
                      .post(vm.apiUrl+'/hyperviseur_engine.php', formDataEngine)
                      .then(function (responseEngine) {    
                        if(responseEngine.data){
                          
                        }
                      }).catch(function (errorEngine) {   
                        alertStore.error('Erreur Axios 1: '+errorEngine+' '+element.name);
                      });
                    }
                  }
                }).catch(function (errorEngineCheck) {   
                  //alertStore.error('Erreur Axios 3: '+errorEngineCheck+' '+element.name);
                });           
      } catch (error) {   
        alertStore.error(error);
      }     
  }
},
};
</script>
<style>
.userPanel .v-table > .v-table__wrapper > table > thead > tr > th{
  padding : 0px !important;
}
</style>
