import { Home, Port,PortDetail } from '@/views/hyperviseur';

export default {
    path: '/hyperviseur',
    component: () => import('../layouts/default'),
    children: [
        { path: '', component: Home },
        { path: 'port', component: Port },
        { path: 'port/:id', component: PortDetail }
    ],
    meta: { requiresAuth: true }
};