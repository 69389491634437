<template>   
  <v-dialog
      v-model="dialog"
      :width="(isMobile)?'80%':'60%'"
    >
      <v-card>
        <v-toolbar      
            class="border border-b-sm"
            color="white"
          >
            <v-icon
                :icon="dialogTitle.icon"
                size="large"
                :color="dialogTitle.color"
                class="my-2 ml-5 mr-3"
            ></v-icon>
            <v-divider :thickness="3" color="error" vertical></v-divider>
            <v-toolbar-title class="text-h6">             
              <span :color="(dialogTitle.color != '')?dialogTitle.color:'grey-darken-3'">{{ dialogTitle.title }}</span>         
            </v-toolbar-title>
            <template v-slot:append>      
              <v-btn
                  icon="mdi-close"
                  density="comfortable"
                  variant="plain"
                  @click="dialog = false"
              ></v-btn>
            </template>
          </v-toolbar>

        <v-card-text>
          <v-list-item :key="`dialog-${index}`" v-for="(text,index) in dialogContent">
              <v-list-item-title class="my-label">{{text}}
              </v-list-item-title>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  <v-container fluid class="page-content">
        <v-window v-model="windowsTab">
          <v-window-item class="px-2 py-2">
            <v-expansion-panels variant="accordion">
              <v-expansion-panel
                v-for="hydroweb in hydrowebs"
                :key="hydroweb.id"
                :bg-color="(hydroweb.com == 1)?'white':'grey-lighten-3'"                
              >
                <v-expansion-panel-title :class="(isMobile)?'px-1 pt-4 pb-1':'pa-3'">
                  <template v-slot:default="{ expanded }">
                    <v-row no-gutters>
                          <v-col class="d-flex justify-start" :cols="(isMobile)?'2':'1'">  
                            <v-badge text-color="white" color="orange-darken-1" :content="hydroweb.alarme" v-if="hydroweb.alarme > 0">
                              <v-btn color="orange-darken-1" size="x-small" class="flash" @click.stop="displayDialog(hydroweb.id,'Alarme')" icon="mdi-alarm-light"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="x-small" icon="mdi-alarm-light" variant="text" v-else>              
                            </v-btn> 
                          </v-col>
                          <v-col class="d-flex justify-start" :cols="(isMobile)?'2':'1'">  
                            <v-badge text-color="white" color="red-darken-1" :content="hydroweb.default" v-if="hydroweb.default > 0">
                              <v-btn color="red-darken-1" size="x-small" class="flash" @click.stop="displayDialog(hydroweb.id,'Défaut')" icon="mdi-alert"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="x-small" icon="mdi-alert" variant="text" v-else>
                            </v-btn> 
                          </v-col>
                      <v-col class="d-flex justify-start pl-2 pt-2" :cols="(isMobile)?'5':'3'">
                        {{ hydroweb.name }}
                      </v-col>
                      <v-col class="d-flex justify-start pl-0 pt-2" cols="3">
                          <v-icon
                            :icon="(hydroweb.puissanceValue > hydroweb.pmax/2)?'mdi-speedometer':'mdi-speedometer-slow'"
                            start
                            size="small"
                          ></v-icon>
                          {{ hydroweb.puissanceValue }}
                      </v-col>
                      <!--<v-col
                        class="text-grey"
                        cols="8"
                      >
                        <v-fade-transition leave-absolute>
                          <span
                            v-if="expanded"
                            key="0"
                          >
                            Enter a name for the trip
                          </span>
                          <span
                            v-else
                            key="1"
                          >
                            hihi
                          </span>
                        </v-fade-transition>
                      </v-col>-->
                    </v-row>
                  </template>
                  <template v-slot:actions>
                    <v-icon :color="(hydroweb.com == 1)?'green':'red'">
                      mdi-compare-vertical
                    </v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>               
                  <v-row>
                    <v-col xxl="1" xl="1" lg="3" md="3" cols="12">
                      <v-card class="mx-2 my-2 pb-9">
                          <div
                            class="text-subtitle-1 transition-swing text-center"
                          >
                          <highcharts :options="hydroweb.puissance"></highcharts>
                          <!--<apexchart type="radialBar" height="250" width="100%" :options="hydroweb.puissance" :series="hydroweb.puissance.series"></apexchart>-->
                          </div>
                      </v-card>
                    </v-col>
                    <v-col xxl="3" xl="3" lg="5" md="5" cols="12">
                      <v-card class="mx-2 my-2 pb-9">
                              <v-container class="niveaux-box" fluid>
                                <apexchart
                                  type="bar"
                                  height="200"
                                  :options="hydroweb.niveau"
                                  :series="hydroweb.niveauSeries"
                                ></apexchart>
                              </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="puissance-box mx-0 mt-2 mb-5">
                    <v-col xxl="1" xl="1" lg="2" md="2" cols="3" v-for="production in hydroweb.production" :key="production.label" class="ma-0 my-2 pa-2">
                      <v-text-field
                        :model-value="production.value"
                        :label="production.label"
                        variant="outlined"
                        readonly
                        density="compact"
                        class="productionInput"
                      ></v-text-field>
                    </v-col>                             
                  </v-row>
                  <v-bottom-navigation absolute horizontal grow style="left:0px;width: calc((100%) - 0px);">
                      <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('journal',hydroweb.id)">
                        <v-icon>mdi-comment-alert</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('historique',hydroweb.id)">
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-dns</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-factory</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-waves</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('statistiques',hydroweb.id)">
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('cameras',hydroweb.id)">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('rapport',hydroweb.id)">
                        <v-icon>mdi-notebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-tools</v-icon>
                      </v-btn>
                  </v-bottom-navigation>                            
                </v-expansion-panel-text>              
              </v-expansion-panel>
            </v-expansion-panels>       
          </v-window-item>
          <v-window-item class="px-2 py-2">
           
              <v-row density="compact">
                <v-col
                  v-for="hydroweb in hydrowebs"
                  :key="hydroweb.id"
                  xxl="2" xl="3" lg="4" md="6" sm="12" cols="12"
                >
                  <v-card :loading="loading" class="mx-2 my-2 pb-9">
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <v-toolbar
                      color="green"
                      density="compact"
                      v-if="hydroweb.com == 1"
                    >
                      <!--<v-icon icon="mdi-compare-vertical"></v-icon>-->
                      <v-app-bar-title>{{ hydroweb.name }}</v-app-bar-title>
                      
                            
                      <div style="height:100%;position:relative;" :class="(hydroweb.alarme > 0)?'bg-orange ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydroweb.alarme" v-if="hydroweb.alarme > 0">
                              <v-btn color="orange-lighten-4" size="small" class="flash" @click="displayDialog(hydroweb.id,'Alarme')" icon="mdi-alarm-light"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small"  icon="mdi-alarm-light" variant="text" v-else>
                            </v-btn>                      
                      </div>
                      <div style="height:100%;" :class="(hydroweb.default > 0)?'bg-red ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydroweb.default" v-if="hydroweb.default > 0">
                              <v-btn color="red-lighten-4" size="small" class="flash" @click="displayDialog(hydroweb.id,'Défaut')" icon="mdi-alert"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small" icon="mdi-alert" variant="text" v-else>
                            </v-btn> 
                      </div>
                    </v-toolbar>
                    <v-toolbar
                      color="red"
                      density="compact"
                      class="flash"
                      v-else
                    >
                      <v-toolbar-title>{{ hydroweb.name }}</v-toolbar-title>

                      <v-btn icon>
                        <v-icon>mdi-compare-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col md="5" sm="12">
                          <div
                            class="text-subtitle-1 transition-swing text-center"
                          >
                          <!--<apexchart type="radialBar" height="250" width="100%" :options="hydroweb.puissance" :series="hydroweb.puissance.series"></apexchart>-->                     
                          <highcharts :options="hydroweb.puissance"></highcharts>
                          </div>
                        </v-col>
                        <v-col md="7" sm="12">
                          <v-row>
                            <v-col cols="12" style="padding:0px;">
                              <v-container class="niveaux-box" fluid>
                                <apexchart
                                  type="bar"
                                  height="200"
                                  :options="hydroweb.niveau"
                                  :series="hydroweb.niveauSeries"
                                ></apexchart>
                              </v-container>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="puissance-box">
                              <v-col cols="3" v-for="production in hydroweb.production" :key="production.label">
                                <v-text-field
                                  :model-value="production.value"
                                  :label="production.label"
                                  variant="outlined"
                                  readonly
                                  density="compact"
                                ></v-text-field>
                              </v-col>                             
                            </v-row>
                    </v-card-text>
                    <v-bottom-navigation absolute horizontal grow style="left:0px;width: calc((100%) - 0px);">
                      <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('journal',hydroweb.id)">
                        <v-icon>mdi-comment-alert</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('historique',hydroweb.id)">
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-dns</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-factory</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-waves</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('statistiques',hydroweb.id)">
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('cameras',hydroweb.id)">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('rapport',hydroweb.id)">
                        <v-icon>mdi-notebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-tools</v-icon>
                      </v-btn>
                    </v-bottom-navigation>
                  </v-card>
                </v-col>
              </v-row>
          </v-window-item>
          <v-window-item>
            <v-row class="mb-0">
              <v-col v-for="item in legendMapItems" :key="item.text" cols="auto">
                <v-icon :color="item.color" :icon="item.icon" size="small"></v-icon>
                {{ item.text }}
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col xxl="10" xl="10" lg="9" md="8" cols="12">               
                <VMap style="height:1000px" :center="mapCenter" zoom="10">
                  <VMapGoogleTileLayer />
                  <VMapZoomControl />
                  <VMapAttributionControl />
                    <VMapPinMarker
                      :latlng="[hydroweb.latitude,hydroweb.longitude]"
                      icon-color="#000000"
                      :icon-background-color="(hydroweb.com)?(hydroweb.default)?'#E57373':'#66BB6A':'#616161'"
                      :icon-placeholder-color="(hydroweb.com)?(hydroweb.alarme)?'#FFCC80':'#C8E6C9':'#E0E0E0'"
                      @click="selectedIndex=index"
                      v-for="(hydroweb,index) in hydrowebs" :key="hydroweb.id"
                    >
                      {{ hydroweb.puissanceValue }}
                    </VMapPinMarker>
                </VMap>
              </v-col>
              <v-col xxl="2" xl="2" lg="3" md="4" cols="12">
                <v-card :loading="loading" class="mx-2 my-0 pb-9">
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <v-toolbar
                      color="green"
                      density="compact"
                      v-if="hydrowebs[selectedIndex].com == 1"
                    >
                      <!--<v-icon icon="mdi-compare-vertical"></v-icon>-->
                      <v-app-bar-title>{{ hydrowebs[selectedIndex].name }}</v-app-bar-title>
                      <div style="height:100%;position:relative;" :class="(hydrowebs[selectedIndex].alarme > 0)?'bg-orange ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydrowebs[selectedIndex].alarme" v-if="hydrowebs[selectedIndex].alarme > 0">
                              <v-btn color="orange-lighten-4" size="small" class="flash" @click="displayDialog(hydrowebs[selectedIndex].id,'Alarme')" icon="mdi-alarm-light"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small"  icon="mdi-alarm-light" variant="text" v-else>
                            </v-btn>                      
                      </div>
                      <div style="height:100%;" :class="(hydrowebs[selectedIndex].default > 0)?'bg-red ma-0 px-3 py-2':'bg-green ma-0 px-3 py-2'">
                        <v-badge text-color="white" color="transparent" :content="hydrowebs[selectedIndex].default" v-if="hydrowebs[selectedIndex].default > 0">
                              <v-btn color="red-lighten-4" size="small" class="flash" @click="displayDialog(hydrowebs[selectedIndex].id,'Défaut')" icon="mdi-alert"  variant="text">
                              </v-btn>
                            </v-badge>                   
                            <v-btn color="green-lighten-4" size="small" icon="mdi-alert" variant="text" v-else>
                            </v-btn> 
                      </div>
                    </v-toolbar>
                    <v-toolbar
                      color="red"
                      density="compact"
                      class="flash"
                      v-else
                    >
                      <v-toolbar-title>{{ hydrowebs[selectedIndex].name }}</v-toolbar-title>

                      <v-spacer></v-spacer>
                      COM
                      <v-btn icon>
                        <v-icon>mdi-compare-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <div
                            class="text-subtitle-1 transition-swing text-center"
                          >
                          <!--<apexchart type="radialBar" height="250" width="100%" :options="hydroweb.puissance" :series="hydroweb.puissance.series"></apexchart>-->                     
                          <highcharts :options="hydrowebs[selectedIndex].puissance"></highcharts>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="12" style="padding:0px;">
                              <v-container class="niveaux-box" fluid>
                                <apexchart
                                  type="bar"
                                  height="200"
                                  :options="hydrowebs[selectedIndex].niveau"
                                  :series="hydrowebs[selectedIndex].niveauSeries"
                                ></apexchart>
                              </v-container>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="puissance-box my-4">
                              <v-col cols="3" v-for="production in hydrowebs[selectedIndex].production" :key="production.label">
                                <v-text-field
                                  :model-value="production.value"
                                  :label="production.label"
                                  variant="outlined"
                                  readonly
                                  density="compact"
                                ></v-text-field>
                              </v-col>                             
                            </v-row>
                    </v-card-text>
                    <v-bottom-navigation absolute horizontal grow style="left:0px;width: calc((100%) - 0px);">
                      <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('journal',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-comment-alert</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('historique',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-dns</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-factory</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-waves</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('statistiques',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('cameras',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                      <v-btn icon @click="redirect('rapport',hydrowebs[selectedIndex].id)">
                        <v-icon>mdi-notebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-tools</v-icon>
                      </v-btn>
                    </v-bottom-navigation>
                  </v-card>                
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>       
  </v-container>
</template>
<script>
var apiFileUrl = "api_domms.php";
//import { createApp } from "vue";
import axios from "axios";
import Highcharts from "highcharts";
//import { Chart } from "highcharts-vue";
//import Highcharts from "highcharts";
import hcMore from "highcharts/highcharts-more";
//import Vidle from 'v-idle'
import { VMap, VMapGoogleTileLayer , VMapZoomControl, VMapAttributionControl, VMapPinMarker } from 'vue-map-ui';
hcMore(Highcharts);
//import moment from "moment";
/*import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import highchartsMore from "highcharts/highcharts-more";*/
//const app = createApp();
/*highchartsMore(Highcharts);
app.use(HighchartsVue, {
highcharts: Highcharts,
});*/
import { useAuthStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useNavbarStore } from '@/stores';

export default {
  components: {
    VMap,
    VMapGoogleTileLayer,
    VMapZoomControl,
    VMapAttributionControl,
    VMapPinMarker,
  },
  props : {
  },
  data () {
    return {
    title : 'Hyperviseur',
    tabs: [{id : 0, value : 0, hideMobile : 0, icon : 'mdi-view-list'},
          {id : 1, value : 1, hideMobile : 1, icon : 'mdi-view-grid'},
          {id : 2, value : 2, hideMobile : 1, icon : 'mdi-map'}],
    tab : 0,
    right : 'searchSelect',
    value: "",
    loading: false,
    mapmode:false,
    dialog:false,
    dialogContent:[],
    hydrowebs : [],
    test : 0,
    intervalId: null,
    dataInit : false,
    timeRefresh : 15000,
    mapCenter : [43.114753,1.607953],
    selectedIndex : 1,
    legendMapItems : [{icon: 'mdi-map-marker', color:'#66BB6A', text: 'Ok'},
                      {icon: 'mdi-map-marker', color:'#E57373', text: 'Défaut'},
                      {icon: 'mdi-circle', color:'#FFCC80', text: 'Alarme'},
                      {icon: 'mdi-map-marker', color:'#616161', text: 'COM perdue'},                   
                      {icon: 'mdi-numeric-9-plus-circle', color:'#C8E6C9', text: 'Valeur de la puissance'},],
    dialogTitle : {title : 'Informations', color : 'grey-darken-3', icon : 'mdi-information-box'}
  }
  },  
computed: {
  windowsTab:  () => {
    const navbarStore = useNavbarStore();
    console.log(navbarStore.getTab());
    return navbarStore.getTab();
  },
  isMobile : () => {
    const navbarStore = useNavbarStore();
    return navbarStore.getMobile();
  }
},
mounted() {
    this.init();
    this.startInterval();
  },
methods: { 
  startInterval: function() {
    this.intervalId = setInterval(() => {
      this.getData();
    }, this.timeRefresh);
  },
  getData(){
    if(this.dataInit){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      console.log('REFRESH'+Date());
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydrowebByUser');
        formData.append('id', authStore.user.id_user);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {    
            if(response.data){         
              var idListHydroweb = vm.hydrowebs.map(el => el.id);
              for (var id in response.data) {                          
                    var indexHydroweb = idListHydroweb.indexOf(response.data[id].id); 
                    if(response.data[id].hyperviseur[2]){                                                 
                      var idListNiveau = vm.hydrowebs[indexHydroweb].niveauSeries[0].data.map(el => el.x);             
                      for (let index = 0; index < response.data[id].hyperviseur[2].length; index++) {
                        var indexNiveau = idListNiveau.indexOf(response.data[id].hyperviseur[2][index].name); 
                        vm.hydrowebs[indexHydroweb].niveauSeries[0].data[indexNiveau].y = response.data[id].hyperviseur[2][index].value;
                      }
                    }
                    if(response.data[id].hyperviseur[1]){
                      vm.hydrowebs[indexHydroweb].puissanceValue = response.data[id].hyperviseur[1][0].value;
                      vm.hydrowebs[indexHydroweb].puissance.series[0].data[0] = response.data[id].hyperviseur[1][0].value;
                    }
                    
                    var idListProduction = vm.hydrowebs[indexHydroweb].production.map(el => el.label);  
                    if(response.data[id].hyperviseur[3]){                        
                      var indexProduction = idListProduction.indexOf(response.data[id].hyperviseur[3][0].nameType);
                      vm.hydrowebs[indexHydroweb].production[indexProduction].value = response.data[id].hyperviseur[3][0].value
                    }                     
                    vm.hydrowebs[indexHydroweb].com = response.data[id].com
                    vm.hydrowebs[indexHydroweb].alarme = response.data[id].alarme
                    vm.hydrowebs[indexHydroweb].default = response.data[id].default                                     
                }                         
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
    }
  },
  init(){
      const authStore = useAuthStore();
      const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(this.tab);
      navbarStore.setRight('tabs'); 
      navbarStore.setSearch(false);
      navbarStore.setButton(false);
      navbarStore.setButtons(false);
      //const api = this.apiUrl;
      try {        
        var vm = this;
        vm.hydrowebs = [];
        var formData = new FormData();
        formData.append('action', 'getHydrowebByUser');
        formData.append('id', authStore.user.id_user);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {    
            if(response.data){
              for (var id in response.data) {            
                    var dataNiveau = [];
                    var goals = [];
                    var puissance = 0;
                    var production = [];
                    var nivMin = 0;
                    var nivMax = 1000;
                    var pmax = 1000;
                    var pmin = 0;
                    var ppercent = 0;
                    if(response.data[id].hyperviseur[2]){                                                         
                      for (let index = 0; index < response.data[id].hyperviseur[2].length; index++) {
                        var goals = [];
                        if(response.data[id].hyperviseur[9] && response.data[id].hyperviseur[10][0].id_ressource == response.data[id].hyperviseur[2][index].id_ressource){
                          goals.push({
                                            name: response.data[id].hyperviseur[9][0].name,
                                            value: response.data[id].hyperviseur[9][0].value,
                                            strokeWidth: 10,
                                            strokeHeight: 0,
                                            strokeLineCap: "round",
                                            strokeColor: "#775DD0",
                                          });
                        }
                        if(response.data[id].hyperviseur[7]){
                        goals.push({
                                            name: response.data[id].hyperviseur[7][0].name,
                                            value: response.data[id].hyperviseur[7][0].value,
                                            strokeWidth: 2,
                                            strokeDashArray: 2,
                                            strokeColor: "#775DD0",
                                          });
                        nivMin = response.data[id].hyperviseur[7][0].value - 200;
                      }
                      if(response.data[id].hyperviseur[8]){
                        goals.push({
                                            name: response.data[id].hyperviseur[8][0].name,
                                            value: response.data[id].hyperviseur[8][0].value,
                                            strokeWidth: 2,
                                            strokeDashArray: 2,
                                            strokeColor: "#775DD0",
                                          });
                        nivMax = response.data[id].hyperviseur[8][0].value +200;
                      }
                        dataNiveau.push({
                                        x: response.data[id].hyperviseur[2][index].name,
                                        y: response.data[id].hyperviseur[2][index].value,
                                        goals: goals,
                                        index : index
                                      })
                      }
                    }
                    if(response.data[id].hyperviseur[1]){
                      puissance = response.data[id].hyperviseur[1][0].value;
                      if(response.data[id].hyperviseur[11]){
                        pmax = 0;
                        for (let index = 0; index < response.data[id].hyperviseur[11].length; index++) {
                          pmax = pmax + response.data[id].hyperviseur[11][index].value;
                        }
                      }
                      if(response.data[id].hyperviseur[12]){
                        pmin = 1000;
                        for (let index = 0; index < response.data[id].hyperviseur[12].length; index++) {
                          if(response.data[id].hyperviseur[12][index].value < pmin){
                            pmin = response.data[id].hyperviseur[12][index].value;
                          }
                        }
                      }
                      ppercent = puissance * 100 / pmax;
                    }
                    for (let j = 3; j < 7; j++) {
                      if(response.data[id].hyperviseur[j]){
                        production.push({label : response.data[id].hyperviseur[j][0].nameType, value : response.data[id].hyperviseur[j][0].value});
                      }
                    }                   
                            vm.hydrowebs.push({
                              id : response.data[id].id,
                              name : response.data[id].name,
                              com : response.data[id].com,
                              latitude : response.data[id].latitude,
                              longitude : response.data[id].longitude,
                              alarme: response.data[id].alarme,
                              default: response.data[id].default,
                              puissanceValue: puissance,
                              pmax: pmax,
                              puissance: {
                                chart: {
                                    type: 'gauge',
                                    plotBackgroundColor: null,
                                    plotBackgroundImage: null,
                                    plotBorderWidth: 0,
                                    plotShadow: false,
                                    height: '80%'
                                },

                                title: {
                                    text: ''
                                },

                                credits : {
                                    enabled : false
                                },

                                pane: {
                                    startAngle: -90,
                                    endAngle: 89.9,
                                    background: null,
                                    center: ['50%', '75%'],
                                    size: '110%'
                                },

                                // the value axis
                                yAxis: {
                                    min: 0,
                                    max: pmax,
                                    tickPixelInterval: pmax / (pmax/10),
                                    tickPosition: 'inside',
                                    tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
                                    tickLength: 20,
                                    tickWidth: 2,
                                    minorTickInterval: null,
                                    labels: {
                                        distance: 15,
                                        style: {
                                            fontSize: '10px'
                                        }
                                    },
                                    lineWidth: 0,
                                    plotBands: [{
                                        from: 0,
                                        to: pmin,
                                        color: '#e9e442', // yellow
                                        thickness: 20
                                    }, {
                                        from: pmin,
                                        to: pmax - (pmax * 0.05),
                                        color: '#4CAF50', // green
                                        thickness: 20
                                    }, {
                                        from: pmax - (pmax * 0.05),
                                        to: pmax,
                                        color: '#F44336', // red
                                        thickness: 20
                                    }],
                                },

                                series: [{
                                    name: 'Puissance',
                                    data: [puissance],
                                    tooltip: {
                                        valueSuffix: ' kW'
                                    },
                                    dataLabels: {
                                        format: '{y} kW',
                                        borderWidth: 0,
                                        color: (
                                            Highcharts.defaultOptions.title &&
                                            Highcharts.defaultOptions.title.style &&
                                            Highcharts.defaultOptions.title.style.color
                                        ) || '#333333',
                                        style: {
                                            fontSize: '16px'
                                        }
                                    },
                                    dial: {
                                        radius: '80%',
                                        backgroundColor: 'gray',
                                        baseWidth: 12,
                                        baseLength: '0%',
                                        rearLength: '0%'
                                    },
                                    pivot: {
                                        backgroundColor: 'gray',
                                        radius: 6
                                    }

                                }]
                              },
                              niveau: {
                                chart: {
                                  height: 200,
                                  type: "bar",
                                  toolbar: {
                                    tools: {
                                      download: false,
                                    },
                                  },
                                },
                                plotOptions: {
                                  bar: {
                                    horizontal: true,
                                  },
                                },
                                xaxis: {
                                  tickAmount : 2,
                                  labels: {
                                  },
                                  min: nivMin,
                                  max: nivMax,
                                },
                                yaxis: {
                                  show : true,
                                },
                                colors: ["#00E396"],
                                dataLabels: {
                                  textAnchor: "start",
                                  offsetX: 0,
                                  style: {
                                    fontSize: "10px",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontWeight: "normal",
                                    colors: ["#333", "#999"],
                                  },
                                },
                                legend: {
                                  show: true,
                                  showForSingleSeries: true,
                                  customLegendItems: ["Niveau", "Seuil / Régulation"],
                                  markers: {
                                    fillColors: ["#00E396", "#775DD0"],
                                  },
                                },
                                tooltip: {
                                  fixed: {
                                      enabled: true,
                                      position: 'topRight',
                                      offsetX: 0,
                                      offsetY: 0,
                                  },
                                },
                              },
                              niveauSeries : [{name: "Niveau",data: dataNiveau}],
                              production : production
                            });       
                            /*let hydrowebsIndexList = vm.hydrowebs.map(el => el.id);
                            let hydrowebIndex = hydrowebsIndexList.indexOf(response.data[id].id); 
                            console.log(vm.hydrowebs[hydrowebIndex].puissance.plotOptions.radialBar.dataLabels.value.formatter = function(){vm.hydrowebs[hydrowebIndex].puissanceValue +"kW"}); */                              
                }
                vm.$nextTick(() => { 
                  vm.dataInit = true;
                });
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 
  },
  checkReadyState(checker) {
    if (checker.readyState === 4) {
        if ((checker.status == 200) || (checker.status == 0)) {     
          console.log('OK');
        }
        else {          
          console.log('NOK');
          return;
        }
    }
  },
  testApi:  () => {
    var formData = new FormData();
    //formData.append('action', 'getOldPalette');
    //var vm = this;
    axios
      .post($this.apiUrl+'/'+apiFileUrl, formData)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  displayDialog(id,title){
    const alertStore = useAlertStore();
    var action = '';
    var icon = 'mdi-information-box';
    var color = 'grey-darken-3';
    if(title == 'Alarme'){
      action = 'getAlarmeByHydroweb';
      icon = 'mdi-alarm-light';
      color = 'orange-darken-1';
    }else if(title == 'Défaut'){
      action = 'getDefautByHydroweb';
      icon = 'mdi-alert';
      color = 'red-darken-1';
    }
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', action);
        formData.append('id', id);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {         
          if(response.data){
            vm.dialog = true;
            vm.dialogTitle.title = title;
            vm.dialogTitle.color = color;
            vm.dialogTitle.icon = icon;
            vm.dialogContent = [];  
            for (let i = 0; i < response.data.length; i++) {
              vm.dialogContent.push(response.data[i].text);            
            }
          }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {   
        alertStore.error(error);
      } 

  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  redirect(route,id){
    const navbarStore = useNavbarStore();
    navbarStore.setSearchSelected([id]);
      console.log(navbarStore.getSearchSelected())
    window.location.href = '#/'+route+'/'; 
  }
},
};
</script>
<style scoped>
.highcharts-figure,
.highcharts-data-table table {
min-width: 310px;
max-width: 500px;
margin: 1em auto;
}

.highcharts-data-table table {
font-family: Verdana, sans-serif;
border-collapse: collapse;
border: 1px solid #ebebeb;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}

.highcharts-data-table caption {
padding: 1em 0;
font-size: 1.2em;
color: #555;
}

.highcharts-data-table th {
font-weight: 600;
padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
background: #f8f8f8;
}

.highcharts-data-table tr:hover {
background: #f1f7ff;
}
.v-item-group.v-bottom-navigation .v-btn {
min-width: 0px !important;
}
.v-item-group.v-bottom-navigation--horizontal
.v-btn
> .v-btn__content
> .v-icon {
margin-right: 0px !important;
}

.centrale-box{
  padding-bottom: 55px;
}

.puissance-box {
padding: 0px;
margin-top: -35px;
}
.puissance-box .puissance-chart {
margin-bottom: 25px !important;
}
.puissance-box .v-row .v-col {
padding: 2px !important;
}

.niveaux-box {
margin-top: -35px;
}
.alarme-box {
text-align: center;
padding-top: 0px !important;
}
.comTrue {
background-color: #00b62d;
}

.comFalse {
background-color: #c90300;
animation-name: COM0;
animation-duration: 2s;
animation-iteration-count: infinite;
}

.v-btn--stacked.v-btn--size-default {
    min-width: 30px;
    padding: 0 0px;
}
@keyframes COM0 {
0% {
  background-color: #c90300;
}
25% {
  background-color: #c90300d3;
}
50% {
  background-color: #c90300b7;
}
75% {
  background-color: #c90300b9;
}
100% {
  background-color: #c90300e0;
}
}
</style>
<style>
/*import styles VUE MAP*/
@import 'leaflet/dist/leaflet.css';
@import 'vue-map-ui/dist/normalize.css';
@import 'vue-map-ui/dist/style.css';
@import 'vue-map-ui/dist/theme-all.css';
.productionInput input{
  padding: 0.5em !important;
  text-align: center !important;
  font-size : 0.9em;
}

.marker-round-icon {
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 23px;
  text-align: center;
}
</style>
